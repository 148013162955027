import {
  FETCH_PRODUCTS_ERROR,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS
} from './types/product';

import Axios from 'axios';
import { setNotify } from './notify';

// const token = `0911f88dd190789f16ee1ec43d0340de360f4895ed2d4204091bb63bf3c1bd95da7161d4b689`;

const fetchProductsRequest = loading => ({
  type: FETCH_PRODUCTS_REQUEST,
  loading
});
const fetchProductsError = error => ({
  type: FETCH_PRODUCTS_ERROR,
  error
});

export const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  products
});

const generateQuery = (details, keys) => {
  let query = '';

  if (details && Object.keys(details).length) {
    query += '?';

    keys.map(p => {
      if (details[p]) {
        query += `&&${p}=${details[p]}`;
      }
      return null;
    });
  }

  return query;
};

export const fetchProducts = details => dispatch => {
  dispatch(fetchProductsRequest(true));
  return Axios.get(`/products${generateQuery(details, ['type', 'max', 'min'])}`)
    .then(async ({ data }) => {
      console.log(data.data);
      return dispatch(fetchProductsSuccess(data.data));
    })
    .catch(error => {
      dispatch(fetchProductsRequest(false));
      if (error.response) {
        dispatch(fetchProductsError(error.response.data));
        if (error.response.data.errors) {
          return dispatch(
            setNotify({
              title: error.response.data.errors.param,
              body: error.response.data.errors.msg,
              type: 'error'
            })
          );
        } else {
          return dispatch(
            setNotify({
              title: 'Error',
              body: error.response.data.message,
              type: 'error'
            })
          );
        }
      }
    });
};
