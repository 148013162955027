import {
  FETCH_PAYMENT_PLAN_ERROR,
  FETCH_PAYMENT_PLAN_REQUEST,
  FETCH_PAYMENT_PLAN_SUCCESS
} from './types/paymentPlan';

import Axios from 'axios';
import { setNotify } from './notify';

// const token = `0911f88dd190789f16ee1ec43d0340de360f4895ed2d4204091bb63bf3c1bd95da7161d4b689`;

const fetchPaymentPlansRequest = loading => ({
  type: FETCH_PAYMENT_PLAN_REQUEST,
  loading
});
const fetchPaymentPlansError = error => ({
  type: FETCH_PAYMENT_PLAN_ERROR,
  error
});

export const fetchPaymentPlansSuccess = plans => ({
  type: FETCH_PAYMENT_PLAN_SUCCESS,
  plans
});

export const fetchPaymentPlans = () => dispatch => {
  dispatch(fetchPaymentPlansRequest(true));
  return Axios.get('/payment-plan')
    .then(async ({ data }) => {
      console.log(data.data);
      dispatch(fetchPaymentPlansSuccess(data.data));
    })
    .catch(error => {
      dispatch(fetchPaymentPlansRequest(false));
      if (error.response) {
        dispatch(fetchPaymentPlansError(error.response.data));
        if (error.response.data.errors) {
          return dispatch(
            setNotify({
              title: error.response.data.errors.param,
              body: error.response.data.errors.msg,
              type: 'error'
            })
          );
        } else {
          return dispatch(
            setNotify({
              title: 'Error',
              body: error.response.data.message,
              type: 'error'
            })
          );
        }
      }
    });
};
