import React from 'react';
import Styles from './index.module.scss';
import { NavLink } from 'react-router-dom';


const PlanCard = ({
  top,
  bodyText,
  topColor,
  pricing,
  lower,
  actText,
  whereTo
}) => {
  return (
    <div className={Styles.planCard}>
      <div className={`${Styles.top} ${topColor ? Styles.diffBg : ''}`}>{top}</div>
      <div className={Styles.body}>
        {pricing ? <h2>{pricing}</h2> : null}
        {lower ? <div>{lower}</div> : null}
        <hr/>
        <p>{bodyText}</p>
      </div>
      <div className={Styles.bottom}>
        <NavLink
          to={whereTo}
          className={Styles.link}
        >
          {actText}
        </NavLink>
      </div>
    </div>
  );
};

export default PlanCard;
