import { Redirect, Route } from 'react-router-dom';

import React from 'react';
import { useMappedState } from 'redux-react-hook';

const ProtectedRoute = ({ component: Component, ...configs }) => {
  const user = useMappedState(({ auth }) => auth.currentUser);

  return (
    <Route
      {...configs}
      render={props =>
        user ? (
          // user.hasPassword ? (
          <Component {...props} />
        ) : (
          // ) : (
          //   <Redirect to="/onboarding" />
          // )
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default ProtectedRoute;
