import React from 'react';
import Styles from './index.module.scss';

const Hero = ({ mainHeading, subHeading }) => {
  return (
    <div className={Styles.heroContainer}>
      <div className={Styles.heroInnerContainer}>
        <h1>{mainHeading}</h1>
        {subHeading ? <p>{subHeading}</p> : null}
      </div>
    </div>
  );
};

export default Hero;
