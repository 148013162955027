import React, { useEffect, useState } from 'react';

import styles from './index.module.scss';
import siteLogo from 'Assets/logo/property-purse-logo-black.svg';

const ComingSoon = () => {
  const [timer, setTimer] = useState({});

  useEffect(() => {
    const now = new Date();
    const launch = new Date('2020-02-02');
    const timeLeft = launch - now;
    const days = Math.floor(timeLeft / (1000 * 3600 * 24));
    const hours = Math.floor(((timeLeft / (1000 * 3600 * 24)) % 1) * 24);
    const mins = Math.floor(
      ((((timeLeft / (1000 * 3600 * 24)) % 1) * 24) % 1) * 60
    );
    const secs = Math.floor(
      ((((((timeLeft / (1000 * 3600 * 24)) % 1) * 24) % 1) * 60) % 1) * 60
    );
    setTimer({
      days,
      hours,
      mins,
      secs
    });

    document.title = `${days}:${hours}:${mins}:${secs} left to launch!`;

    return () => {
      setTimer({});
    };
  }, [timer]);
  return (
    <main className={styles.mainSection}>
      <section className={styles.leftSection}>
        <div className={styles.logoImg}>
          <img src={siteLogo} alt="logo" />
        </div>
        <div className={styles.headline}>
          Monitor.
          <br />
          Automate.
          <br />
          <span>LAND MORTGAGES</span>
        </div>
        <hr />
        <div className={styles.timezone}>
          <p>Our website is coming in</p>
          <div className={styles.timer}>
            <div className={styles.time}>
              <div>{`${timer.days}`.padStart(2, 0)}</div>
              <span>Days</span>
            </div>
            <div className={styles.colon}>:</div>
            <div className={styles.time}>
              <div>{`${timer.hours}`.padStart(2, 0)}</div>
              <span>Hours </span>
            </div>
            <div className={styles.colon}>:</div>
            <div className={styles.time}>
              <div>{`${timer.mins}`.padStart(2, 0)}</div>
              <span>Minutes</span>
            </div>
            <div className={styles.colon}>:</div>
            <div className={styles.time}>
              <div>{`${timer.secs}`.padStart(2, 0)}</div>
              <span>Seconds</span>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.rightSection}>
        <form action="POST">
          <p className={styles.heading}>
            For now let us know how we can help you
          </p>
          <p className={styles.tagline}>
            Fill the required fields below so we can get in touch with you
          </p>
          <div>
            <label htmlFor="fullName">
              <input
                type="text"
                name="fullName"
                id="fullName"
                placeholder="Full Name"
                required
                // value={fullName}
                // onChange={e => setFullName(e.target.value)}
              />
            </label>
          </div>
          <div>
            <label htmlFor="email">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                required
                // value={fullName}
                // onChange={e => setFullName(e.target.value)}
              />
            </label>
          </div>
          <div>
            <label htmlFor="landSpec">
              <input
                type="text"
                name="landSpec"
                id="landSpec"
                placeholder="Land Size (Plot)"
                required
                // value={fullName}
                // onChange={e => setFullName(e.target.value)}
              />
            </label>
          </div>
          <div>
            <label htmlFor="location">
              <input
                type="text"
                name="location"
                id="location"
                placeholder="Land Location "
                required
                // value={fullName}
                // onChange={e => setFullName(e.target.value)}
              />
            </label>
          </div>
          <div>
            <label htmlFor="price">
              <input
                type="text"
                name="price"
                id="price"
                placeholder="Land Price (in Naira)"
                required
                // value={fullName}
                // onChange={e => setFullName(e.target.value)}
              />
            </label>
          </div>
          <input
            type="submit"
            className={styles.subscribeButton}
            // disabled={loading}
            value="Submit"
          />
        </form>
      </section>
    </main>
  );
};

export default ComingSoon;
