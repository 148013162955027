import { Redirect, Route } from 'react-router-dom';

import DashBoard from 'Pages/Dashboard';
import React from 'react';
import { useMappedState } from 'redux-react-hook';

const DashboardRoute = ({ component: Component, ...configs }) => {
  const user = useMappedState(({ auth }) => auth.currentUser);

  return (
    <Route
      {...configs}
      render={props =>
        user ? (
          // user.hasPassword ? (
          <DashBoard>
            <Component {...props} />
          </DashBoard>
        ) : (
          // ) : (
          //   <Redirect to="/onboarding" />
          // )
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default DashboardRoute;
