import React from 'react';
import NewHeader from 'Components/NewHeader';
import NewHero from 'Components/NewHero';
import Footer from 'Components/NewFooter';

import Styles from './index.module.scss';
import AdvisorySection from 'Components/AdvisorySection';
import GetStartedSection from 'Components/GetStartedSection';

const OurCompany = () => {
  return (
    <div>
      <NewHeader />
      <NewHero mainHeading="Our Company" />
      <section className={Styles.section__one}>
        <div className={Styles.section__one__container}>
          <div className={Styles.vision__one}>
            <h2>Vision Statement</h2>
            <p>
              To be a world class Real estate Marketing Firm of first choice in
              Nigeria
            </p>
          </div>
          <div className={Styles.vision__two}>
            <h2>With a Vision</h2>
            <p>
              To create Estates for Africans and spread the idea across Africa
              for us to acquire assets across our countries and to attract
              Africans all over the world to buy property in Africa with a
              reliable real estate payment and acquisition plan.
            </p>
          </div>
        </div>
        <div></div>

        <div className={Styles.blueOffsetBox} />
        <div className={Styles.greenOffsetBox} />
      </section>

      <section className={Styles.section__two}>
        <div className={Styles.section__two__container}>
          <h2>Our Core Values</h2>
          <div className={Styles.core__values__wrapper}>
            <div className={Styles.core__values__box}>
              <h3>EXCELLENCE</h3>
              <p>
                We render fast, timely <br /> and efficient services.
              </p>
              <span className={Styles.green}>1</span>
            </div>
            <div className={Styles.core__values__box}>
              <h3>TEAM WORK</h3>
              <p>
                We are united in purpose in creating <br /> ultimate customer
                satisfaction.
              </p>
              <span className={Styles.blue}>2</span>
            </div>
            <div className={Styles.core__values__box}>
              <h3>RESILIENCE</h3>
              <p>
                We go the whole length to <br /> delight our customers.
              </p>
              <span className={Styles.blue}>3</span>
            </div>
            <div className={Styles.core__values__box}>
              <h3>CLARITY</h3>
              <p>
                Our offers are simple and do not <br /> have any hidden charges.
              </p>
              <span className={Styles.green}>4</span>
            </div>
          </div>
        </div>

        <div className={Styles.blueOffsetBox} />
        <div className={Styles.greenOffsetBox} />
      </section>

      <section className={Styles.section__three}>
        <div className={Styles.section__three__container}>
          <h2>What Makes Us Unique?</h2>

          <div className={Styles.section__three__inner__container}>
            <div className={Styles.section__three__box}>
              <p>
                We render fast, timely <br /> and efficient services.
              </p>
            </div>
            <div className={Styles.section__three__box}>
              <p className={Styles.green}>
                We market and sell <br /> value properties.
              </p>
            </div>
            <div className={Styles.section__three__box}>
              <p>
                We give customers value for <br /> their money and offer <br />
                best bargains.
              </p>
            </div>
            <div className={Styles.section__three__box}>
              <p>
                We offer peace and comfort <br /> in transactions such that our
                <br />
                customers don't have to worry of <br /> any unpleasant
                occurrence.
              </p>
            </div>
            <div className={Styles.section__three__box}>
              <p>
                We are a socially responsible and <br /> responsive organization
                that seeks <br />
                to give to society the part of what <br /> it has gotten from
                it.
              </p>
            </div>
            <div className={Styles.section__three__box}>
              <p>
                We encourage investment across <br /> individuals from various{' '}
                <br />
                socio-economic backgrounds.
              </p>
            </div>
          </div>
        </div>
      </section>
      <AdvisorySection />
      <GetStartedSection />
      <Footer />
    </div>
  );
};

export default OurCompany;
