import {
  CURRENT_PLAN_ERROR,
  CURRENT_PLAN_REQUEST,
  CURRENT_PLAN_SUCCESS,
  ONBOARD_PLAN_ERROR,
  ONBOARD_PLAN_REQUEST,
  ONBOARD_PLAN_SUCCESS
} from './types/plan';

import Axios from 'axios';
import { setNotify } from './notify';

const onboardPlanRequest = loading => ({
  type: ONBOARD_PLAN_REQUEST,
  loading
});
const onboardPlanError = error => ({
  type: ONBOARD_PLAN_ERROR,
  error
});

export const onboardPlanSuccess = plan => ({
  type: ONBOARD_PLAN_SUCCESS,
  plan
});

const currentPlanRequest = loading => ({
  type: CURRENT_PLAN_REQUEST,
  loading
});
const currentPlanError = error => ({
  type: CURRENT_PLAN_ERROR,
  error
});

export const currentPlanSuccess = plan => ({
  type: CURRENT_PLAN_SUCCESS,
  plan
});

export const onboardplan = (details, action) => async dispatch => {
  dispatch(onboardPlanRequest(true));

  try {
    const { data } = await Axios.patch('/user-plan/onboard', { ...details });

    if (data.status !== 'success') {
      return dispatch(
        setNotify({
          title: 'Error',
          body: data.message,
          type: 'error'
        })
      );
    }
    dispatch(onboardPlanSuccess(data.data));
    return action && action();
  } catch (error) {
    dispatch(onboardPlanRequest(false));
    if (error.response) {
      dispatch(onboardPlanError(error.response.data));
      if (error.response.data.errors) {
        return dispatch(
          setNotify({
            title: error.response.data.errors.param,
            body: error.response.data.errors.msg,
            type: 'error'
          })
        );
      } else {
        return dispatch(
          setNotify({
            title: 'Error',
            body: error.response.data.message,
            type: 'error'
          })
        );
      }
    }
  }
};

export const getCurrentPlan = () => async dispatch => {
  dispatch(currentPlanRequest(true));

  try {
    const { data } = await Axios.get('/user-plan/current');

    if (data.status !== 'success') {
      return dispatch(
        setNotify({
          title: 'Error',
          body: data.message,
          type: 'error'
        })
      );
    }
    return dispatch(currentPlanSuccess(data.data));
  } catch (error) {
    dispatch(currentPlanRequest(false));
    if (error.response) {
      dispatch(currentPlanError(error.response.data));
      if (error.response.data.errors) {
        return dispatch(
          setNotify({
            title: error.response.data.errors.param,
            body: error.response.data.errors.msg,
            type: 'error'
          })
        );
      } else {
        return dispatch(
          setNotify({
            title: 'Error',
            body: error.response.data.message,
            type: 'error'
          })
        );
      }
    }
  }
};
