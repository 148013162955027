import Axios from 'axios';
import { authUserSuccess } from '../store/actions/auth';

const setUser = store => {
  Axios.defaults.baseURL = 'https://proppurse-api.herokuapp.com/api/v1/';
  let user = null;
  const rawUser = localStorage.getItem('user');
  if (rawUser) {
    user = JSON.parse(rawUser);
  }

  // if (user) {
  //   const lastVisited = localStorage.getItem('lastVisited');
  //   if (!lastVisited) {
  //     try {
  //       localStorage.clear();
  //     } catch (error) {}
  //   }

  //   if (new Date() - new Date(lastVisited) > 10000) {
  //     try {
  //       localStorage.clear();
  //     } catch (error) {}
  //   }
  // }

  if (user) {
    const token = localStorage.getItem('token');
    if (localStorage.getItem('user')) {
      Axios.defaults.headers.common['x-access-token'] = token;

      store.dispatch(authUserSuccess(user));
    }
  }
};

export default setUser;
