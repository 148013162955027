import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './index.module.scss';

const GetStartedSection = () => {
  return (
    <section className={Styles.sectionFive}>
      <p>
        Ready to save <br />
        <span>for your future ?</span>
      </p>
      <Link to="/">Get Started</Link>
    </section>
  );
};

export default GetStartedSection;
