import React from 'react';
import Styles from './index.module.scss';

const AdvisorySection = () => {
  return (
    <section className={Styles.sectionFour}>
      <div className={Styles.sectionFour__leftText}>
        <p>
          <span>We also provide</span>
          <br />
          real estate advisory <br /> services and easy-to-understand payment
          plans
        </p>
      </div>
      <div className={Styles.sectionFour__rightText}>
        <p>
          We specialize in the provision of premium service through exceptional
          marketing to help facilitate real estate investments for all our
          clients.
        </p>
        <hr />
      </div>
    </section>
  );
};

export default AdvisorySection;
