import NewHeader from 'Components/NewHeader';
import React from 'react';
import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push('/');
  };
  return (
    <div className={styles.lost}>
      <NewHeader />
      <section>
        <div className={styles.box}></div>
        <div className={styles.box1}></div>
        <div className={styles.box2}></div>
        <div className={styles.box3}></div>
        <div className={styles.box4}></div>
        <div className={styles.box6}></div>
        <div className={styles.box7}></div>
        <div className={styles.box8}></div>
        <div className={styles.box9}></div>
        <div className={styles.message}>oops!</div>
        <div className={styles.four}>404</div>
        <div className={styles.notfound}>Page not found</div>
        <button type="button" onClick={handleClick} className={styles.btn}>
          Return to home
        </button>
      </section>
    </div>
  );
};

export default NotFound;
