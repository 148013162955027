import React from 'react';
import styles from './index.module.scss';

export default function ProgressFooter({ stage }) {
  return (
    <div className={styles.progress}>
      <span className={`${styles.step} ${stage >= 1 ? styles.active : ''}`}>
        <span className={styles.footTitle}>STEP 1</span>
      </span>
      <span
        className={`${styles.seperator} ${stage >= 2 ? styles.active : ''}`}
      />
      <span className={`${styles.step} ${stage >= 2 ? styles.active : ''}`}>
        <span className={styles.footTitle}>STEP 2</span>
      </span>
      <span
        className={`${styles.seperator} ${stage >= 3 ? styles.active : ''}`}
      />
      <span className={`${styles.step} ${stage >= 3 ? styles.active : ''}`}>
        <span className={styles.footTitle}>STEP 3</span>
      </span>
      <span
        className={`${styles.seperator} ${stage >= 4 ? styles.active : ''}`}
      />
      <span className={`${styles.step} ${stage >= 4 ? styles.active : ''}`}>
        <span className={styles.footTitle}>STEP 4</span>
      </span>
    </div>
  );
}
