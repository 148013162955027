import { FaChevronDown, FaChevronUp, FaFilter } from 'react-icons/fa';
import React, { useState } from 'react';

import styles from './index.module.scss';

export default function ButtonCard({ icon, title, desc, blue, right }) {
  return (
    <div
      className={`${styles.card}  ${blue ? styles.blue : ''} ${
        right ? styles.right : ''
      }`}
    >
      <div className={styles.title}>{title}</div>
      <div className={styles.desc}>{desc}</div>
      <div className={styles.icon}>
        <img src={icon} alt={`${title}`} />
      </div>
    </div>
  );
}
