/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import style from './accord.module.scss';

const Accordian = ({ title, children }) => {
  const [open, setopen] = useState(false);
  return (
    <div className={style.accord}>
      <div className={style.title} onClick={() => setopen(!open)}>
        <span>{title}</span>
        <div className={style.cross}>
          {!open && <div className={style.top}></div>}
          <div className={style.down}></div>
        </div>
      </div>
      {open && <div className={style.body}>{children}</div>}
    </div>
  );
};

export default Accordian;
