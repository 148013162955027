import {
  CURRENT_PLAN_ERROR,
  CURRENT_PLAN_REQUEST,
  CURRENT_PLAN_SUCCESS,
  ONBOARD_PLAN_ERROR,
  ONBOARD_PLAN_REQUEST,
  ONBOARD_PLAN_SUCCESS
} from 'store/actions/types/plan';
import { SET_FORM, SET_INPUTS } from 'store/actions/types/input';

const INITIAL_STATE = {
  loading: false,
  currentPlan: {
    duration: null,
    paymentPlan: null,
    product: null
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${SET_FORM}_PLAN`:
      return {
        ...state,
        [action.form]: action.value
      };
    case `${SET_INPUTS}_PLAN`:
      return {
        ...state,
        [action.form]: {
          ...state[action.form],
          [action.key]: action.value
        }
      };
    case CURRENT_PLAN_REQUEST:
      return {
        ...state,
        loading: action.loading,
        error: null
      };
    case CURRENT_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CURRENT_PLAN_SUCCESS:
      return {
        ...state,
        currentPlan: action.plan,
        loading: false
      };

    case ONBOARD_PLAN_REQUEST:
      return {
        ...state,
        loading: action.loading,
        error: null
      };
    case ONBOARD_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ONBOARD_PLAN_SUCCESS:
      return {
        ...state,
        plan: action.plan,
        loading: false
      };
    default:
      return state;
  }
};
