import React, { useEffect } from 'react';
import { getCurrentPlan, onboardplan } from 'store/actions/plan';
import { useDispatch, useMappedState } from 'redux-react-hook';

import DropDown from 'Components/DropDown';
import ProgressFooter from 'Components/ProgressFooter';
import SpinnerDot from 'Components/SpinnerDot';
import building from 'Assets/images/modern-building.jpg';
import { setInputValue } from 'store/actions/inputs';
import { setNotify } from 'store/actions/notify';
import siteLogo from 'Assets/logo/property-purse-logo-black.svg';
import styles from '../index.module.scss';
import { useHistory } from 'react-router-dom';

export default function OnboardDuration() {
  const STAGE = 2;
  const history = useHistory();
  const dispatch = useDispatch();

  const { loading, selectedProduct, duration } = useMappedState(
    ({ product, auth, plan }) => ({
      products: product.products,
      loading: plan.loading,
      selectedProduct: plan.currentPlan.product,
      duration: plan.currentPlan.duration,
      user: auth.currentUser
    })
  );

  useEffect(() => {
    dispatch(getCurrentPlan());
  }, []);
  function navigateTo(path) {
    return history.push(path);
  }
  const options = [
    {
      name: 'Select years',
      action: () =>
        dispatch(setInputValue('PLAN', 'currentPlan', 'duration', null))
    }
  ];

  [...new Array(10)].map((a, i) =>
    options.push({
      name: `${i + 1} year${i > 0 ? 's' : ''}`,
      action: () =>
        dispatch(setInputValue('PLAN', 'currentPlan', 'duration', i + 1))
    })
  );
  return (
    <div className={styles.container}>
      <section className={styles.mainPage}>
        <div className={styles.left}>
          <img src={siteLogo} alt="site logo" />

          <div>
            <h3>Property Summary</h3>
          </div>

          <div className={styles.summary}>
            <div className={styles.leftDetail}>
              <div className={styles.inner}>
                <img src={building} alt="property" />

                <div className={styles.details}>
                  <div>
                    <span>NAME</span>
                    <p>{selectedProduct && selectedProduct.name}</p>
                  </div>
                  <div>
                    <span>LOCATION</span>
                    <p>
                      {selectedProduct &&
                        selectedProduct.location &&
                        selectedProduct.location.name}
                    </p>
                  </div>
                </div>
              </div>
              <button>More Details</button>
            </div>
            <div className={styles.rightDetail}>
              <span>COST</span>
              <div>
                N
                {selectedProduct &&
                  selectedProduct.price &&
                  selectedProduct.price.toLocaleString()}
              </div>
            </div>
          </div>

          <div className={styles.dropper}>
            <p>
              Select Duration to make payment ({duration} year
              {duration && duration > 1 && 's'})
            </p>
            <br />
            <DropDown big blue options={options} />
          </div>
          <div className={styles.navbutton}>
            <button
              onClick={() => navigateTo('/onboard/gallery')}
              className={styles.prev}
            >
              BACK
            </button>
            <div className={styles.centerthem}>
              {loading && <SpinnerDot color="#3352cb9c" size="30" />}
              <button
                disabled={loading}
                onClick={() => {
                  if (!duration) {
                    return dispatch(
                      setNotify({
                        type: 'error',
                        title: 'Field Required',
                        body: 'Please select a duration'
                      })
                    );
                  }
                  dispatch(
                    onboardplan({ stage: STAGE, duration }, () =>
                      navigateTo('/onboard/plan')
                    )
                  );
                }}
                className={styles.next}
              >
                NEXT
              </button>
            </div>
          </div>
          <ProgressFooter stage={STAGE} />
        </div>
        {/* <div className={styles.right}></div> */}
      </section>
    </div>
  );
}
