import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';

import currency from 'utils/currency';
// import download from '../../assets/download.svg';
import styles from './index.module.scss';
import truncateText from 'utils/truncateText';

const Table = ({
  title,
  header,
  data = [],
  dataType = [],
  showFoot,
  paginate = 8,
  showTitle,
  conditions = {},
  noPage
}) => {
  const styleMap = {
    cash: styles.cash,
    boldText: styles.boldText,
    text: styles.text,
    smallText: styles.smallText,
    truncate: styles.text,
    double: styles.cash,
    badge: styles.badge,
    icon: styles.icon
  };
  const [page, setPage] = useState({
    current: 1,
    pages: 1,
    currentPage: 1,
    remain: data.length,
    counter: paginate
  });
  let renderBody = [];
  useEffect(() => {
    const getPages = Math.ceil(data.length / paginate);
    const getRemains =
      data.length > paginate
        ? data.length - (page.currentPage - 1) * paginate
        : data.length;

    const getCurrent =
      page.currentPage > 1 ? (page.currentPage - 1) * paginate + 1 : 1;
    const getCounter = getRemains > paginate ? paginate : getRemains;

    setPage({
      current: getCurrent,
      pages: getPages,
      remain: getRemains,
      counter: getCounter,
      currentPage: page.currentPage > page.pages ? 1 : page.currentPage
    });
  }, [page.pages, page.currentPage, paginate, data, title]);

  const nextPage = () => {
    if (page.currentPage < page.pages) {
      setPage({
        ...page,
        currentPage: page.currentPage + 1
      });
    }
  };

  const prevPage = () => {
    if (page.currentPage > 1) {
      setPage({
        ...page,
        currentPage: page.currentPage - 1
      });
    }
  };

  if (page.current - 1 + page.counter <= data.length) {
    for (
      let index = page.current - 1;
      index < page.current - 1 + page.counter;
      index++
    ) {
      renderBody.push(
        <>
          <tr key={index}>
            {dataType.map((type, i) => (
              <td key={i} className={styleMap[Object.values(type)[0]]}>
                <div
                  className={
                    Object.values(type)[0] === 'badge'
                      ? conditions.badge === data[index][Object.keys(type)[0]]
                        ? null
                        : styles.red
                      : null
                  }
                >
                  {Object.values(type)[0] === 'cash' && <span>&#8358; </span>}
                  {Object.values(type)[0] === 'truncate' ? (
                    truncateText(data[index][Object.keys(type)[0]], 10, '...')
                  ) : Object.values(type)[0] === 'boolean' ? (
                    conditions.boolean[data[index][Object.keys(type)[0]]]
                  ) : Object.values(type)[0] === 'double' ? (
                    <div
                      style={{
                        display: 'inline-flex',
                        flexDirection: 'column'
                      }}
                    >
                      <div>
                        &#8358; {currency(data[index][Object.keys(type)[0]])}
                      </div>
                      <span
                        className={`${styles.underBadge} ${
                          conditions.badge === data[index][conditions.double]
                            ? null
                            : styles.red
                        }`}
                      >
                        {data[index][conditions.double] || 'unsuccessful'}
                      </span>
                    </div>
                  ) : Object.values(type)[0] === 'action' ? (
                    <a
                      href={`${data[index][Object.keys(type)[0]]}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      {/* <img src={download} alt="" /> */}
                    </a>
                  ) : Object.values(type)[0] === 'cash' ? (
                    currency(data[index][Object.keys(type)[0]])
                  ) : (
                    data[index][Object.keys(type)[0]]
                  )}
                </div>
              </td>
            ))}
          </tr>
        </>
      );
    }
  }

  return (
    <div className={styles.table}>
      {/* <div className={styles.title}>{showTitle && <div>{title}</div>}</div> */}
      {/* <table
        id={`${title && title.split(' ').join('')}`}
        style={{ display: 'none' }}
      >
        <thead>
          <tr>{header && header.map((head, i) => <th key={i}>{head}</th>)}</tr>
        </thead>
        <tbody>
          {data.map((datum, index) => (
            <>
              <tr key={index}>
                {dataType.map((type, i) => (
                  <td key={i}>
                    <div>
                      {Object.values(type)[0] === 'cash' && (
                        <span>&#8358; </span>
                      )}
                      {Object.values(type)[0] === 'truncate' ? (
                        datum[Object.keys(type)[0]]
                      ) : Object.values(type)[0] === 'boolean' ? (
                        conditions.boolean[datum[Object.keys(type)[0]]]
                      ) : Object.values(type)[0] === 'double' ? (
                        <div
                          style={{
                            display: 'inline-flex',
                            flexDirection: 'column'
                          }}
                        >
                          <div>&#8358; {datum[Object.keys(type)[0]]}</div>
                          <span>{datum[conditions.double]}</span>
                        </div>
                      ) : Object.values(type)[0] === 'cash' ? (
                        currency(datum[Object.keys(type)[0]])
                      ) : (
                        datum[Object.keys(type)[0]]
                      )}
                    </div>
                  </td>
                ))}
              </tr>
              <tr className={styles.extra} key={index * 0.1}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    */}
      <table>
        <thead>
          <tr>{header && header.map((head, i) => <th key={i}>{head}</th>)}</tr>
        </thead>

        <tbody>{renderBody}</tbody>
        {/* {showFoot && (
          <tfoot>
            <tr>
              {data.length > paginate && (
                <td colSpan={header.length / 2}>
                  Showing {page.current} - {page.current - 1 + page.counter} of{' '}
                  {data.length}
                </td>
              )}

              {data.length > paginate && (
                <td colSpan={header.length / 2}>
                  <div>
                    {
                      <button
                        onClick={page.currentPage > 1 ? prevPage : null}
                        className={page.currentPage > 1 ? styles.show : null}
                      >
                        <span className={styles.prev} /> Previous
                      </button>
                    }
                    <div>
                      {page.currentPage}/{page.pages}
                    </div>
                    {
                      <button
                        onClick={
                          page.currentPage < page.pages ? nextPage : null
                        }
                        className={
                          page.currentPage < page.pages ? styles.show : null
                        }
                      >
                        Next <span className={styles.next} />
                      </button>
                    }
                  </div>
                </td>
              )}
            </tr>
          </tfoot>
        )} */}
      </table>
      {showFoot && data.length > paginate && (
        <div className={styles.footer}>
          <div className={styles.paginator}>
            <button
              className={styles.pageBtn}
              onClick={page.currentPage > 1 ? prevPage : null}
            >
              <FaChevronLeft />
            </button>
            <div className={styles.items}>
              {[...new Array(page.pages)].map((p, i) => (
                <div
                  onClick={() =>
                    setPage({
                      ...page,
                      currentPage: i + 1
                    })
                  }
                  className={`${styles.item} ${
                    page.currentPage === i + 1 ? styles.active : ''
                  }`}
                >
                  {i + 1}
                </div>
              ))}
            </div>

            <button
              className={styles.pageBtn}
              onClick={page.currentPage < page.pages ? nextPage : null}
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
