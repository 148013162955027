import React, { useEffect } from 'react';
import { getCurrentPlan, onboardplan } from 'store/actions/plan';
import { useDispatch, useMappedState } from 'redux-react-hook';

import ProgressFooter from 'Components/ProgressFooter';
import SpinnerDot from 'Components/SpinnerDot';
import { fetchPaymentPlans } from 'store/actions/paymentPlan';
import { setInputValue } from 'store/actions/inputs';
import { setNotify } from 'store/actions/notify';
import siteLogo from 'Assets/logo/property-purse-logo-black.svg';
import styles from '../index.module.scss';
import { useHistory } from 'react-router-dom';

export default function OnboardPlan() {
  const STAGE = 3;
  const history = useHistory();

  const dispatch = useDispatch();

  const { loading, paymentPlans, selectedPlan } = useMappedState(
    ({ product, auth, plan, paymentPlan, selectedPlan }) => ({
      products: product.products,
      loading: plan.loading,
      selectedProduct: plan.currentPlan.product,
      duration: plan.duration,
      selectedPlan: plan.currentPlan.paymentPlan,
      paymentPlans: paymentPlan.paymentPlans,
      user: auth.currentUser
    })
  );

  useEffect(() => {
    dispatch(fetchPaymentPlans());
    dispatch(getCurrentPlan());
  }, []);
  function navigateTo(path) {
    return history.push(path);
  }

  return (
    <div className={styles.container}>
      <section className={styles.mainPage}>
        <div className={styles.left}>
          <img src={siteLogo} alt="site logo" />

          <div>
            <h3>Select your preferred plan</h3>
          </div>

          <div className={styles.plans}>
            <div className={styles.plancontent}>
              {paymentPlans.map(plan => (
                <div
                  key={plan.id}
                  className={`${styles.singleplan} ${
                    selectedPlan && plan.id === selectedPlan.id
                      ? styles.active
                      : ''
                  }`}
                  onClick={() => {
                    if (selectedPlan && plan.id === selectedPlan.id) {
                      return dispatch(
                        setInputValue(
                          'PLAN',
                          'currentPlan',
                          'paymentPlan',
                          null
                        )
                      );
                    }
                    return dispatch(
                      setInputValue('PLAN', 'currentPlan', 'paymentPlan', plan)
                    );
                  }}
                >
                  <div className={styles.top}>{plan.name}</div>
                  <div className={styles.body}>
                    {plan.minimum && (
                      <>
                        <h2>{plan.minimum}</h2>
                        <div>Minimum fee deducted</div>
                      </>
                    )}
                    <hr />
                    <p>{plan.description}</p>
                  </div>
                  <div className={styles.bottom}>
                    {selectedPlan && plan.id === selectedPlan.id
                      ? 'selected plan'
                      : 'select plan'}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.navbutton}>
            <button
              onClick={() => navigateTo('/onboard/duration')}
              className={styles.prev}
            >
              BACK
            </button>
            <div className={styles.centerthem}>
              {loading && <SpinnerDot color="#3352cb9c" size="30" />}
              <button
                onClick={() => {
                  if (!selectedPlan || !selectedPlan.id) {
                    return dispatch(
                      setNotify({
                        type: 'error',
                        title: 'Field Required',
                        body: 'Please select a plan'
                      })
                    );
                  }
                  dispatch(
                    onboardplan(
                      { stage: STAGE, paymentPlanId: selectedPlan.id },
                      () => navigateTo('/onboard/payment')
                    )
                  );
                }}
                className={styles.next}
              >
                NEXT
              </button>
            </div>
          </div>
          <ProgressFooter stage={STAGE} />
        </div>
        {/* <div className={styles.right}></div> */}
      </section>
    </div>
  );
}
