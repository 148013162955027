import React, { useEffect, useState } from 'react';

import downChevron from 'Assets/svg/down-chevron.svg';
import styles from './index.module.scss';

const MoveDown = () => {
  const [scrollHeight, setHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const [width, setwidth] = useState(0);
  const [currentPos, setcurrentPos] = useState(0);

  const listenToScroll = () => {
    const pos =
      document.documentElement.scrollTop +
      document.documentElement.clientHeight +
      900;
    const hei = document.documentElement.scrollHeight;
    setHeight(hei);
    setClientHeight(document.documentElement.clientHeight);
    setwidth(document.documentElement.clientWidth);
    setcurrentPos(pos);
  };

  const ScrollToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
      left: 0
    });
  };
  const ScrollToBottom = () => {
    width <= 900
      ? window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: clientHeight - 50
        })
      : window.scrollTo({ behavior: 'smooth', left: 0, top: scrollHeight });
  };
  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);
  return (
    <div className={styles.down}>
      <button
        className={styles.btn}
        onClick={currentPos >= scrollHeight ? ScrollToTop : ScrollToBottom}
      >
        <img
          src={downChevron}
          alt="down chevron"
          className={`${
            currentPos >= scrollHeight && width > 900 ? styles.turn : ''
          }`}
        />
      </button>
    </div>
  );
};

export default MoveDown;
