import 'react-input-range/lib/css/index.css';
import './range.scss';

import {
  FaCheck,
  FaChevronCircleRight,
  FaChevronLeft,
  FaChevronRight
} from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import { getCurrentPlan, onboardplan } from 'store/actions/plan';
import { useDispatch, useMappedState } from 'redux-react-hook';

import DropDown from 'Components/DropDown';
import InputRange from 'react-input-range';
import ProgressFooter from 'Components/ProgressFooter';
import SpinnerDot from 'Components/SpinnerDot';
import building from 'Assets/images/modern-building.jpg';
import { fetchProducts } from 'store/actions/product';
import { setInputValue } from 'store/actions/inputs';
import { setNotify } from 'store/actions/notify';
import siteLogo from 'Assets/logo/property-purse-logo-black.svg';
import styles from '../index.module.scss';
import { useHistory } from 'react-router-dom';
import GalleryModal from 'Components/GalleryModal';

export default function OnboardGallery() {
  const dispatch = useDispatch();

  const { products, loading, user, selectedProduct, saving } = useMappedState(
    ({ product, auth, plan }) => ({
      products: product.products,
      loading: product.loading,
      selectedProduct: plan.currentPlan.product,
      saving: plan.loading,
      user: auth.currentUser
    })
  );
  const [priceRange, setPriceRange] = useState({ min: 200000, max: 10000000 });
  const [productType, setProductType] = useState(null);
  const [galleryOpen, setGalleryOpen] = useState(false);

  // const [selectedProduct, setselectedProduct] = useState(null);
  const history = useHistory();
  function navigateTo(path) {
    return history.push(path);
  }

  let galleryscroll;

  const STAGE = 1;

  useEffect(() => {
    const { min, max } = priceRange;
    dispatch(fetchProducts({ type: productType, min, max }));
  }, [productType, priceRange]);

  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(getCurrentPlan());
  }, []);

  return (
    <div className={styles.container}>
      <section className={styles.mainPage}>
        <div className={styles.left}>
          <img src={siteLogo} alt="site logo" />
          <div>
            <h2>Hello {user.firstName},</h2>
            <p>You are a few steps away from getting your first home</p>
          </div>
          <div>
            <h3>Our Properties</h3>
            <p>Select a property</p>
          </div>

          <div className={styles.filters}>
            <span>Filter by: </span>
            <DropDown
              disbaled={loading}
              options={[
                { name: 'Select Type', action: () => setProductType(null) },
                { name: 'by Land', action: () => setProductType('land') },
                {
                  name: 'by Building',
                  action: () => setProductType('building')
                }
              ]}
            />
            <div className={styles.pricerange}>
              <span className={styles.extra}>Price Range</span>
              <div className={styles.range}>
                <InputRange
                  disabled={loading}
                  maxValue={10000000}
                  minValue={200000}
                  formatLabel={value => `${value.toLocaleString()}`}
                  step={200000}
                  value={priceRange}
                  onChange={value => setPriceRange(value)}
                  onChangeComplete={value => console.log(value)}
                />
              </div>
            </div>
          </div>
          <div className={styles.galleryContainer}>
            <button
              className={styles.scrollLeft}
              onClick={() => {
                galleryscroll &&
                  galleryscroll.scrollTo({
                    behavior: 'smooth',
                    left: galleryscroll.scrollLeft - 260,
                    top: 0
                  });
              }}
            >
              <FaChevronLeft />
            </button>
            {loading && (
              <div
                style={{
                  flex: 1,
                  height: '283px',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <SpinnerDot size="70" color="#3eb337" />
              </div>
            )}
            {!loading && (
              <div
                className={styles.gallery}
                ref={item => {
                  galleryscroll = item;
                }}
              >
                {products.map(product => (
                  <div
                    key={product.id}
                    className={`${styles.item} ${
                      selectedProduct && selectedProduct.id === product.id
                        ? styles.active
                        : ''
                    }`}
                    onClick={() =>
                      dispatch(
                        setInputValue('PLAN', 'currentPlan', 'product', product)
                      )
                    }
                  >
                    {selectedProduct && selectedProduct.id === product.id && (
                      <div className={styles.check}>
                        <FaCheck />
                      </div>
                    )}
                    <img
                      src={
                        product.media && product.media.length
                          ? product.media[0]
                          : building
                      }
                      alt="product"
                    />
                    <div className={styles.title}>
                      <div>
                        <span>NAME</span>
                        {product.name}
                      </div>
                      <FaChevronCircleRight
                        onClick={() => setGalleryOpen(true)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <button
              className={styles.scrollRight}
              onClick={() => {
                galleryscroll &&
                  galleryscroll.scrollTo({
                    behavior: 'smooth',
                    left: galleryscroll.scrollLeft + 260,
                    top: 0
                  });
              }}
            >
              <FaChevronRight />
            </button>
          </div>
          <div className={styles.navbutton}>
            <div></div>
            <div className={styles.centerthem}>
              {saving && <SpinnerDot color="#3352cb9c" size="30" />}
              <button
                disabled={loading}
                onClick={() => {
                  if (!selectedProduct || !selectedProduct.id) {
                    return dispatch(
                      setNotify({
                        type: 'error',
                        title: 'Field Required',
                        body: 'Please select a product'
                      })
                    );
                  }
                  dispatch(
                    onboardplan(
                      { stage: STAGE, productId: selectedProduct.id },
                      () => navigateTo('/onboard/duration')
                    )
                  );
                }}
                className={styles.next}
              >
                NEXT
              </button>
            </div>
          </div>
          <ProgressFooter stage={STAGE} />
        </div>
      </section>
      <GalleryModal
        data={null}
        open={galleryOpen}
        action={() => setGalleryOpen(false)}
      />
    </div>
  );
}
