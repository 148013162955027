import React, { useState } from 'react';
import editIcon from 'Assets/svg/edit.svg';
import uploadIcon from 'Assets/svg/upload.svg';
import styles from './index.module.scss';
import Table from 'Components/Table';
import { useDispatch, useMappedState } from 'redux-react-hook';
import DropDown from 'Components/DropDown';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ButtonCard from 'Components/ButtonCard';

export default function MainDashboard() {
  const { plan, user } = useMappedState(({ auth, plan }) => ({
    loading: plan.loading,
    plan: plan.currentPlan,
    user: auth.currentUser
  }));
  return (
    <div className={styles.dashboard}>
      <div className={styles.topcard}>
        <div className={styles.title}>Hello, {user.firstName}</div>
        Welcome back <br />
        to your dashboard
      </div>

      <div className={styles.cardBtns}>
        <ButtonCard
          title="Modify Plan"
          desc="Make Changes to your plan"
          icon={editIcon}
        />
        <ButtonCard
          title="Top Up"
          desc="Add more money to your contribution"
          icon={uploadIcon}
          blue
          right
        />
      </div>

      <div className={styles.roadmap}>
        <div className={styles.title}>Transition Roadmap</div>
        <div className={styles.topbar}>
          <DropDown green options={[{ name: 'Payment Progress' }]} />

          <div className={styles.navv}>
            <button>
              <FaChevronLeft />{' '}
            </button>
            <button>
              <FaChevronRight />{' '}
            </button>
          </div>
        </div>
        <div className={styles.main}>
          <div className={styles.map}>
            <p>Map</p>
          </div>
        </div>
      </div>

      <div className={styles.nextContrib}>
        NEXT CONTRIBUTION DATE
        <div className={styles.date}> November 30th, 2020</div>
      </div>
      <div className={styles.table}>
        <div className={styles.top}>
          <div>Payment History</div>
          <div className={styles.buttons}>
            <DropDown
              filter
              light
              options={[{ name: 'Filter' }, { name: 'Recent' }]}
            />
            <DropDown
              light
              options={[{ name: 'Sort By' }, { name: 'By Time' }]}
            />
          </div>
        </div>

        <Table
          paginate={5}
          header={['Payment', 'Amount', 'Bank', 'Date']}
          data={[
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            }
          ]}
          conditions={{
            badge: 'successful'
          }}
          dataType={[
            { payment: 'text' },
            { amount: 'cash' },
            { bank: 'text' },
            { date: 'smallText' }
          ]}
        />
      </div>
    </div>
  );
}
