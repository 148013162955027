import React from 'react';
import style from './partition.module.scss';

const Partition = ({ title, children }) => {
  return (
    <div className={style.partition}>
      <div className={style.left}>
        <div className={style.text}> {title}</div>
        <div className={style.side}>
          <div className={style.top}></div>
          <div className={style.mid}></div>
          <div className={style.bottom}></div>
        </div>
      </div>
      <div className={style.right}>{children}</div>
    </div>
  );
};

export default Partition;
