import { FaChevronDown, FaChevronUp, FaFilter } from 'react-icons/fa';
import React, { useState } from 'react';

import styles from './index.module.scss';

export default function DropDown({
  big,
  blue,
  green,
  light,
  options = [],
  filter
}) {
  const [open, setopen] = useState(false);
  const [title, settitle] = useState(
    options.length ? options[0].name : 'No Selection'
  );
  return (
    <div
      className={`${styles.dropdown} ${big ? styles.big : ''} ${
        blue ? styles.blue : ''
      } ${light ? styles.light : ''}  ${green ? styles.green : ''}`}
    >
      <button className={styles.title} onClick={() => setopen(prev => !prev)}>
        <span>{title}</span>
        {filter ? (
          <FaFilter className={styles.chev} />
        ) : (
          <>
            {open ? (
              <FaChevronUp className={styles.chev} />
            ) : (
              <FaChevronDown className={styles.chev} />
            )}
          </>
        )}
      </button>
      {open && (
        <div className={styles.items}>
          {options.map((option, i) => (
            <button
              className={styles.item}
              onClick={() => {
                settitle(option.name);
                option.action && option.action();
                setopen(false);
              }}
            >
              {option.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
