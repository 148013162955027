import React from 'react';

import NewHeader from 'Components/NewHeader';
import NewHero from 'Components/NewHero';
import styles from './index.module.scss';
import Footer from 'Components/NewFooter';
import Partition from './partition';
import Accordion from './accord';

const Faq = () => {

  return (
    <div className={styles.faq}>
      <NewHeader />
      <NewHero mainHeading="Frequently Asked Questions" />
      {/* <section className={styles.hero}>
        <div className={styles.hero__container}>
          <div className={styles.text}>
            Frequently <br /> Asked Questions
          </div>
        </div>
      </section> */}
      <section className={styles.questions}>
        <Partition title="About Property Purse">
          <Accordion title="Why should I use property purse?">
            We strive to satisfy our customers and bring them the very best and most affordable properties.
          </Accordion>
          <Accordion title="How does it work?">
            All you need to do is click the signup, fill the short form after which you select a product
            of your choosing and the payment plan which best suits your income pattern. Then you enter your
            debit card/ account details and make your first installment.
          </Accordion>
        </Partition>
        <Partition title="Payment">
          <Accordion title="Are there any setup fees or any other charges?">
            There are no setup fees or other charges.
          </Accordion>
          <Accordion title="Are there payment plans for buying property?">
            No content for this yet
          </Accordion>
          <Accordion title="Can I discontinue my purchase any time before maturity and get a refund?">
            No content for this yet
          </Accordion>
          <Accordion title="What kind of debit cards are used on property purse?">
            No content for this yet
          </Accordion>
        </Partition>
      </section>
      <Footer />
    </div>
  );
};

export default Faq;
