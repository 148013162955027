import React from 'react';

import { ReactComponent as RightArrow } from 'Assets/svg/down-chevron-right-white.svg';
import building from 'Assets/images/modern-building.jpg';

import Styles from './index.module.scss';

const GalleryCard = ({ item, action }) => {
  return (
    <div className={Styles.container} onClick={action}>
      <img src={building} alt="building" />
      <div className={Styles.card__title__container}></div>
      <div className={Styles.card__inner__container}>
        <div className={Styles.card__title}>
          <h4>NAME</h4>
          <p>{item.name}</p>
        </div>
        <button>
          <RightArrow />
        </button>
      </div>
    </div>
  );
};

export default GalleryCard;
