import React, { useState } from 'react';

import NumberFormat from 'react-number-format';
import ProgressFooter from 'Components/ProgressFooter';
import siteLogo from 'Assets/logo/property-purse-logo-black.svg';
import styles from '../index.module.scss';
import { useHistory } from 'react-router-dom';

export default function OnboardPayment() {
  const [cardNumber, setCardNumber] = useState(null);
  const [cardExpDate, setCardExpDate] = useState(null);
  const [cardCVV, setCardCVV] = useState(null);
  const STAGE = 4;
  const history = useHistory();

  function navigateTo(path) {
    return history.push(path);
  }

  return (
    <div className={styles.container}>
      <section className={styles.mainPage}>
        <div className={styles.left}>
          <img src={siteLogo} alt="site logo" />

          <div>
            <h3>Payment</h3>
          </div>

          <div className={styles.card}>
            <div className={styles.group}>
              <label>CARDHOLDER NAME</label>
              <input type="text" name="" />
            </div>
            <div className={`${styles.group} ${styles.long}`}>
              <label>CARD NUMBER</label>
              <NumberFormat
                value={cardNumber}
                format="#### - #### - #### - ####"
                onChange={({ value }) => setCardNumber(value)}
                placeholder="0000 - 0000 - 0000 - 0000"
                renderText={value => <input value={value} />}
              />
            </div>
            <div className={styles.double}>
              <div className={styles.group}>
                <label>EXP DATE</label>
                <NumberFormat
                  value={cardExpDate}
                  format="##/##"
                  // mask={['M', 'M', 'Y', 'Y']}
                  onChange={({ value }) => setCardExpDate(value)}
                  placeholder="MM/YY"
                  renderText={value => <input value={value} />}
                />
              </div>
              <div className={styles.group}>
                <label>CVV</label>
                <NumberFormat
                  value={cardCVV}
                  format="####"
                  onChange={({ value }) => setCardCVV(value)}
                  placeholder="3 or 4 digit number"
                  renderText={value => <input value={value} />}
                />
              </div>
            </div>
          </div>

          <div className={`${styles.navbutton} ${styles.type2}`}>
            <button
              onClick={() => navigateTo('/onboard/plan')}
              className={styles.prev}
            >
              BACK
            </button>
            <div className={styles.stack}>
              <div>
                <p>Your plan will be inactive</p>
                <button
                  onClick={() => navigateTo('/dashboard')}
                  className={styles.next}
                >
                  SKIP TO DASHBOARD
                </button>
              </div>
              <button
                onClick={() => navigateTo('/dashboard')}
                className={styles.next}
              >
                NEXT
              </button>
            </div>
          </div>
          <ProgressFooter stage={STAGE} />
        </div>
        {/* <div className={styles.right}></div> */}
      </section>
    </div>
  );
}
