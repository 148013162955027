import React, { useEffect } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';

import addYear from 'utils/addYear';
import { getCurrentPlan } from 'store/actions/plan';
import money from 'Assets/svg/save-money.svg';
import styles from './index.module.scss';
import target from 'Assets/svg/target.svg';
import DisplayCard from 'Components/DisplayCard';

export default function DashboardPlans() {
  const dispatch = useDispatch();
  const { plan, user } = useMappedState(({ auth, plan }) => ({
    loading: plan.loading,
    plan: plan.currentPlan,
    user: auth.currentUser
  }));
  useEffect(() => {
    dispatch(getCurrentPlan());
  }, []);
  return (
    <div className={styles.plans}>
      <h2>Plan Info</h2>
      <div className={styles.plandetails}>
        <div className={styles.detail}>
          <div className={styles.title}>Contribution period</div>
          <div className={styles.sub}>9 Months</div>
        </div>
        <div className={styles.detail}>
          <div className={styles.title}>Total Term</div>
          <div className={styles.sub}>
            {plan.duration || 0} Year{plan.duration > 1 && 's'}
          </div>
        </div>
        <div className={styles.detail}>
          <div className={styles.title}>Maturity Date</div>
          <div className={styles.sub}>
            {addYear(plan.createdAt, plan.duration).toLocaleDateString(
              'en-Us',
              {
                year: 'numeric',
                month: 'long'
              }
            )}
          </div>
        </div>
        <div className={styles.detail}>
          <div className={styles.title}>Monthly Contribution</div>
          <div className={styles.sub}>9 Months</div>
        </div>
        <div className={styles.detail}>
          <div className={styles.title}>Total Contribution</div>
          <div className={styles.sub}>9 Months</div>
        </div>
        <div className={styles.detail}>
          <div className={styles.title}>Total Loan Amount</div>
          <div className={styles.sub}>9 Months</div>
        </div>
        <div className={styles.detail}>
          <div className={styles.title}>Percentage Down Payment</div>
          <div className={styles.sub}>9 Months</div>
        </div>

        <div className={styles.detail}>
          <button>Edit plan</button>
        </div>
      </div>

      <div className={styles.cards}>
        <DisplayCard
          title="Amount Contributed so far"
          amount="500,000"
          icon={money}
        />
        <DisplayCard
          title="Target Amount"
          amount="500,000"
          icon={target}
          blue
        />
      </div>
    </div>
  );
}
