import { FaSpinner } from 'react-icons/fa';
import React from 'react';
import styles from './index.module.scss';

export default function SpinnerDot({ color, size }) {
  return (
    <FaSpinner
      className={styles.spinner}
      color={color}
      style={{
        height: `${size}px`,
        width: `${size}px`
      }}
    />
  );
}
