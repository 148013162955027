import {
  FETCH_PAYMENT_PLAN_ERROR,
  FETCH_PAYMENT_PLAN_REQUEST,
  FETCH_PAYMENT_PLAN_SUCCESS
} from 'store/actions/types/paymentPlan';
import { SET_FORM, SET_INPUTS } from 'store/actions/types/input';

const INITIAL_STATE = {
  loading: false,
  error: null,
  paymentPlans: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${SET_FORM}_PAYMENT_PLAN`:
      return {
        ...state,
        [action.form]: action.value
      };
    case `${SET_INPUTS}_PAYMENT_PLAN`:
      return {
        ...state,
        [action.form]: {
          ...state[action.form],
          [action.key]: action.value
        }
      };
    case FETCH_PAYMENT_PLAN_REQUEST:
      return {
        ...state,
        loading: action.loading,
        error: null
      };
    case FETCH_PAYMENT_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case FETCH_PAYMENT_PLAN_SUCCESS:
      return {
        ...state,
        paymentPlans: action.plans,
        loading: false
      };

    default:
      return state;
  }
};
