import { FaChevronDown, FaChevronUp, FaFilter } from 'react-icons/fa';
import React, { useState } from 'react';

import styles from './index.module.scss';

export default function DisplayCard({ icon, title, amount, blue, right }) {
  return (
    <div
      className={`${styles.card}  ${blue ? styles.blue : ''} ${
        right ? styles.right : ''
      }`}
    >
      <span>{title}</span>
      <hr />
      <p>{amount}</p>

      <div className={styles.icon}>
        <img src={icon} alt={`${title}`} />
      </div>
    </div>
  );
}
