import React from 'react';

import Footer from 'Components/NewFooter';
import NewHeader from 'Components/NewHeader';
import NewHero from 'Components/NewHero';
import PromoCard from 'Components/promoCard';
import styles from './index.module.scss';

const Contact = () => {

  return (
    <div className={styles.contact}>
      <NewHeader />
      <main>
        <NewHero mainHeading="Contact Us"/>
        <hr/>
        <section className={styles.contactCard}>
          <div>
            <p className={styles.header}>Office Address</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing</p>
          </div>
          <div>
            <p className={styles.header}>Tel</p>
            <p>192072510183</p>
          </div>
          <div>
            <p className={styles.header}>Email</p>
            <p>random@gmail.com</p>
          </div>
        </section>
        <hr/>
        <section className={styles.contactForm}>
          <form action="POST">
            <div className={styles.detail}>
              <div>
                <label htmlFor="fullName">
                  <input
                    type="text"
                    name="fullName"
                    id="fullName"
                    placeholder="Full Name"
                    required
                    // value={fullName}
                    // onChange={e => setFullName(e.target.value)}
                  />
                </label>
              </div>
              <div>
                <label htmlFor="email">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    required
                    // value={fullName}
                    // onChange={e => setFullName(e.target.value)}
                  />
                </label>
              </div>
              <div>
                <label htmlFor="phoneNumber">
                  <input
                    type="tel"
                    name="phonenumber"
                    id="phone"
                    placeholder="Phone Number"
                    required
                    // value={fullName}
                    // onChange={e => setFullName(e.target.value)}
                  />
                </label>
              </div>
            </div>
            <div className={styles.message}>
              <label htmlFor="message">
                <textarea
                  type='text'
                  name='message'
                  id='message'
                  placeholder='Message'
                  required
                />
              </label>
            </div>
            <div className={styles.submit}>
              <button>
                <span>Submit</span>
              </button>
            </div>
          </form>
          <PromoCard />
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Contact;
