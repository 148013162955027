import { Link, NavLink, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { ReactComponent as DownChevron } from 'Assets/svg/down-chevron-green.svg';
import { ReactComponent as FacebookIcon } from 'Assets/svg/facebook-icon.svg';
import { ReactComponent as InstagramIcon } from 'Assets/svg/instagram-icon.svg';
import { ReactComponent as LinkedInIcon } from 'Assets/svg/linkedin-icon.svg';
import Styles from './index.module.scss';
import { ReactComponent as TwitterIcon } from 'Assets/svg/twitter-icon.svg';
import noScroll from 'no-scroll';
import siteLogo from 'Assets/logo/property-purse-logo-black.svg';
import siteLogoGold from 'Assets/logo/property-purse-logo-gold.png';
import siteLogoW from 'Assets/logo/property-purse-logo-white.svg';
import { useMappedState } from 'redux-react-hook';

const Header = ({ background, dashboard }) => {
  const [navOpen, setNavOpen] = useState(false);
  const history = useHistory();
  const user = useMappedState(({ auth }) => auth.currentUser);

  const keyPressHandler = e => {
    if (e.keycode === 13) {
      setNavOpen(!navOpen);
    }
  };

  const toggleNav = () => {
    setNavOpen(!navOpen);
    noScroll.toggle();
  };

  useEffect(() => {
    const listener = history.listen(() => {
      if (navOpen) {
        noScroll.off();
      }
    });

    return () => listener();
  }, [history, navOpen]);

  return (
    <header
      className={`${Styles.header} ${background ? Styles.bgd : ''} ${
        dashboard ? Styles.bgddash : ''
      }`}
    >
      <div className={Styles.header__container}>
        <Link
          to={user ? '/dashboard' : '/'}
          className={`${Styles.header__logo} ${
            navOpen ? Styles.header__nav__open : null
          }`}
        >
          <img
            src={background ? siteLogoGold : dashboard ? siteLogoW : siteLogo}
            alt="site logo"
          />
        </Link>

        <nav
          className={`${Styles.header__nav} ${
            navOpen ? Styles.header__nav__open : null
          }`}
        >
          <div
            className={Styles.nav__toggle}
            role="menubar"
            tabIndex={0}
            onClick={toggleNav}
            onKeyPress={keyPressHandler}
          >
            <div />
            <div />
            <div />
          </div>

          <ul className={Styles.nav__inner}>
            {dashboard ? (
              <>
                <li className={Styles.nav__item}>
                  <NavLink
                    to="/dashboard"
                    className={background ? Styles.white : ''}
                    activeClassName={Styles.nav__item__active}
                    onClick={() => toggleNav()}
                    exact
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li className={Styles.nav__item}>
                  <NavLink
                    to="/dashboard/account"
                    className={background ? Styles.white : ''}
                    activeClassName={Styles.nav__item__active}
                    onClick={() => toggleNav()}
                    exact
                  >
                    Account
                  </NavLink>
                </li>
                <li className={Styles.nav__item}>
                  <NavLink
                    to="/dashboard/plans"
                    className={background ? Styles.white : ''}
                    activeClassName={Styles.nav__item__active}
                    onClick={() => toggleNav()}
                    exact
                  >
                    Plans
                  </NavLink>
                </li>
                <li className={Styles.nav__item}>
                  <NavLink
                    to="/dashboard/history"
                    className={background ? Styles.white : ''}
                    activeClassName={Styles.nav__item__active}
                    onClick={() => toggleNav()}
                    exact
                  >
                    History
                  </NavLink>
                </li>
              </>
            ) : (
              <>
                <li className={Styles.nav__item}>
                  <NavLink
                    to="/"
                    className={background ? Styles.white : ''}
                    activeClassName={Styles.nav__item__active}
                    exact
                  >
                    Home
                  </NavLink>
                </li>
                <li
                  className={`${Styles.nav__item} ${Styles.nav__item__about}`}
                >
                  <NavLink
                    to="/about"
                    className={background ? Styles.white : ''}
                    activeClassName={Styles.nav__item__active}
                  >
                    About Us
                    <span>
                      <DownChevron />
                    </span>
                  </NavLink>

                  <div className={`${Styles.nav__item__dropdown}`}>
                    <NavLink
                      to="/about/our-company"
                      activeClassName={Styles.nav__item__dropdown__active}
                    >
                      Our company
                    </NavLink>
                  </div>
                </li>
                <li className={Styles.nav__item}>
                  <NavLink
                    to="/plans"
                    className={background ? Styles.white : ''}
                    activeClassName={Styles.nav__item__active}
                  >
                    Plans
                  </NavLink>
                </li>
                <li className={Styles.nav__item}>
                  <NavLink
                    to="/gallery"
                    className={background ? Styles.white : ''}
                    activeClassName={Styles.nav__item__active}
                  >
                    Gallery
                  </NavLink>
                </li>
                <li className={Styles.nav__item}>
                  <NavLink
                    to="/contact"
                    className={background ? Styles.white : ''}
                    activeClassName={Styles.nav__item__active}
                  >
                    Contact
                  </NavLink>
                </li>
                <li className={Styles.nav__item}>
                  {user ? (
                    <>
                      <NavLink
                        to="/dashboard"
                        className={background ? Styles.white : ''}
                        activeClassName={Styles.nav__item__active}
                      >
                        Dashboard
                      </NavLink>
                    </>
                  ) : (
                    <>
                      <NavLink
                        to="/signup"
                        className={background ? Styles.white : ''}
                        activeClassName={Styles.nav__item__active}
                      >
                        Get Started
                      </NavLink>
                    </>
                  )}
                </li>
              </>
            )}
            <div className={Styles.mobile__social}>
              <h4>follow us on social media</h4>

              <div className={Styles.mobile__social__icons}>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <TwitterIcon />
                </a>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <InstagramIcon />
                </a>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <FacebookIcon />
                </a>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <LinkedInIcon />
                </a>
              </div>
            </div>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
