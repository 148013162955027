import { FaDashcube, FaHistory, FaList, FaWallet } from 'react-icons/fa';
import React, { useState } from 'react';

import { NavLink } from 'react-router-dom';
import siteLogo from 'Assets/logo/property-purse-logo-white.svg';
import styles from './index.module.scss';

export default function SideBar() {
  const [mini, setMini] = useState(
    document.documentElement.clientWidth <= 1000
  );
  return (
    <div className={`${styles.sidebar} ${mini ? styles.mini : ''}`}>
      <img src={siteLogo} className={styles.logo} />

      <button onClick={() => setMini(prev => !prev)}>
        <div></div>
        <div></div>
        <div></div>
      </button>
      <div className={styles.links}>
        <NavLink exact to="/dashboard" activeClassName={styles.active}>
          <FaDashcube />
          <span>Dashboard</span>
        </NavLink>
        <NavLink to="/dashboard/account" activeClassName={styles.active}>
          <FaWallet />
          <span>Account</span>
        </NavLink>
        <NavLink to="/dashboard/plans" activeClassName={styles.active}>
          <FaList />
          <span>Plans</span>
        </NavLink>
        <NavLink to="/dashboard/history" activeClassName={styles.active}>
          <FaHistory />
          <span>History</span>
        </NavLink>
      </div>
    </div>
  );
}
