import { FaBell, FaChevronDown, FaChevronUp, FaRegBell } from 'react-icons/fa';
import React, { useState } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';

import NewHeader from 'Components/NewHeader';
import SideBar from 'Components/SideBar';
import siteLogo from 'Assets/logo/property-purse-logo-white.svg';
import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';
import generateAvatar from 'utils/generateAvatar';

const DashBoard = ({ children }) => {
  const [open, setopen] = useState(false);
  const history = useHistory();
  const { plan, user } = useMappedState(({ auth, plan }) => ({
    loading: plan.loading,
    plan: plan.currentPlan,
    user: auth.currentUser
  }));
  return (
    <div className={styles.dashboard}>
      <SideBar />
      <div className={styles.allcontent}>
        <NewHeader dashboard />
        <div className={styles.nav}>
          <div className={styles.notify}>
            <FaRegBell size="" />
            <div className={styles.badge} />
          </div>
          <button
            className={styles.title}
            onClick={() => setopen(prev => !prev)}
          >
            <div className={styles.avatar}>
              <img src={generateAvatar(user.firstName)} alt="" />
            </div>
            {open ? (
              <FaChevronUp className={styles.chev} />
            ) : (
              <FaChevronDown className={styles.chev} />
            )}

            {open && (
              <div className={styles.items}>
                <button
                  className={styles.item}
                  onClick={() => {
                    localStorage.clear();
                    history.push('/login');
                  }}
                >
                  Sign Out
                </button>
              </div>
            )}
          </button>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default DashBoard;
