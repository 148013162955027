import NewHeader from 'Components/NewHeader';
import NewHero from 'Components/NewHero';
// import PromoCard from 'Components/promoCard';
import React from 'react';
import Styles from './index.module.scss';

// import aboutSideImg from '../../Assets/images/aboutImg/houseSideImg.png';
import paymentMethodIcon from '../../Assets/svg/payment-method-1.svg';
import peopleIcon from '../../Assets/svg/people-1.svg';
import buildingIcon from '../../Assets/svg/building-1.svg';
import Footer from 'Components/NewFooter';

import building from 'Assets/images/modern-building.jpg';
import AdvisorySection from 'Components/AdvisorySection';
import GetStartedSection from 'Components/GetStartedSection';

const subHeadingText =
  'Property purse is a real estate marketing platform aimed at assisting youths In Nigeria and Africa at large in the easy aquisition of property all over Africa.';

const NewAbout = () => {
  return (
    <div>
      <NewHeader />
      <NewHero mainHeading="About Us" subHeading={subHeadingText} />
      <section className={Styles.mainFocus}>
        <div className={Styles.blueOffsetBox} />
        <div className={Styles.mainFocus__text}>
          <h3>Our Main Focus</h3>
          <p>
            Our main focus here at property purse is on the youths who earn less
            than the average income and who wish to actively invest in real
            estate: our payment plans especially make real investment easy and
            affordable for these youths.
          </p>
        </div>
      </section>
      <section className={Styles.sectionTwo}>
        <div className={Styles.greenOffsetBox} />
        <div className={Styles.sectionTwo__inner}>
          <div className={Styles.sectionTwo__inner__TextSide}>
            <p className={Styles.sectionTwo__textOne}>
              Many people lack the funds to purchase real estate and it seems
              like a wild dream to acquire real estate. Property Purse intends
              to fix the issue by making convenient property payments and house
              developments in a convenient and super easy way.
            </p>
            <p className={Styles.sectionTwo__textTwo}>
              We specialize in the provision of premium service through
              exceptional marketing.
            </p>
          </div>
          <div className={Styles.sectionTwo__inner__imageSide}>
            <img src={building} alt="about building" />
          </div>
        </div>
      </section>

      <section className={Styles.sectionThree}>
        <h3>What we do</h3>
        <div className={Styles.sectionThree__inner}>
          <div className={Styles.sectionThree__box}>
            <div className={Styles.sectionThree__box__image}>
              <img src={peopleIcon} alt="people" />
            </div>
            <p>Help the youth save on a daily or weekly basis</p>
          </div>

          <div className={Styles.sectionThree__box}>
            <div className={Styles.sectionThree__box__image}>
              <img src={buildingIcon} alt="building" />
            </div>
            <p>Provide the knowledge of real estate investment</p>
          </div>

          <div className={Styles.sectionThree__box}>
            <div className={Styles.sectionThree__box__image}>
              <img src={paymentMethodIcon} alt="payment method" />
            </div>
            <p>Make buying of land easy and convenient.</p>
          </div>
        </div>
      </section>
      <AdvisorySection />
      <GetStartedSection />
      <Footer />
    </div>
  );
};

export default NewAbout;
