import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import styles from './index.module.scss';
import building from 'Assets/images/modern-building.jpg';

const GalleryModal = ({ data, open, action }) => {
  let galleryImageScroll;
  return (
    <div className={`${styles.gallerymodal} ${open ? styles.active : ''}`}>
      <div className={styles.backdrop} onClick={action} />
      <div className={styles.content}>
        <div className={styles.head}>
          <div className={styles.title}>PROPERTY INFORMATION</div>
          <hr />
        </div>
        <div className={styles.details}>
          <div className={styles.inner}>
            <div className={styles.tile}>
              <div className={styles.top}>Property Name</div>
              <div className={styles.bottom}>Lorem Ipsum</div>
            </div>

            <div className={styles.topTile}>
              <div className={styles.top}>Description</div>
              <div className={styles.desc}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                <button className={styles.more}>see more</button>
              </div>
            </div>
          </div>
          <div className={styles.inner}>
            <div className={styles.tile}>
              <div className={styles.top}>Location</div>
              <div className={styles.bottom}>Lekki, Lagos</div>
            </div>
            <div className={styles.box}>
              <span>COST</span>
              <div>N 1,000,000</div>
            </div>
          </div>
        </div>
        <div className={styles.head}>
          <div className={styles.title}>PROPERTY IMAGES </div>
          <hr />
        </div>

        <div className={styles.innerGallery}>
          <div className={styles.images}>
            <div
              className={styles.container}
              ref={item => {
                galleryImageScroll = item;
              }}
            >
              <div className={styles.image}>
                <img src={building} alt="product" />
              </div>
              <div className={styles.image}>
                <img src={building} alt="product" />
              </div>
              <div className={styles.image}>
                <img src={building} alt="product" />
              </div>
            </div>
            <button
              className={styles.scrollLeft}
              onClick={() => {
                galleryImageScroll &&
                  galleryImageScroll.scrollTo({
                    behavior: 'smooth',
                    left: galleryImageScroll.scrollLeft - 537,
                    top: 0
                  });
              }}
            >
              <FaChevronLeft />
            </button>
            <button
              className={styles.scrollRight}
              onClick={() => {
                galleryImageScroll &&
                  galleryImageScroll.scrollTo({
                    behavior: 'smooth',
                    left: galleryImageScroll.scrollLeft + 537,
                    top: 0
                  });
              }}
            >
              <FaChevronRight />
            </button>
          </div>

          <div className={styles.thumbs}>
            <div
              onClick={() => {
                galleryImageScroll &&
                  galleryImageScroll.scrollTo({
                    behavior: 'smooth',
                    left: 537 * 0,
                    top: 0
                  });
              }}
              className={styles.thumb}
            >
              <img src={building} alt="product" />
            </div>
            <div
              onClick={() => {
                galleryImageScroll &&
                  galleryImageScroll.scrollTo({
                    behavior: 'smooth',
                    left: 537 * 1,
                    top: 0
                  });
              }}
              className={styles.thumb}
            >
              <img src={building} alt="product" />
            </div>
            <div
              onClick={() => {
                galleryImageScroll &&
                  galleryImageScroll.scrollTo({
                    behavior: 'smooth',
                    left: 537 * 2,
                    top: 0
                  });
              }}
              className={styles.thumb}
            >
              <img src={building} alt="product" />
            </div>
          </div>
        </div>

        <div className={styles.foot}>
          <h2>Like this property ?</h2>
          <button>Get Started</button>
        </div>
      </div>
    </div>
  );
};

export default GalleryModal;
