import React from 'react';
import Styles from './index.module.scss';
import { NavLink } from 'react-router-dom';


const PromoCard = () => {
  return (
    <div className={Styles.promoCard}>
      <div className={Styles.inner}>
        <h2>Ready to save</h2>
        <h2>for your future ?</h2>
        <NavLink
          to="/signup"
        >
          Get Started
        </NavLink>
      </div>
    </div>
  );
};

export default PromoCard;
