import { FaChevronDown, FaChevronUp, FaFilter } from 'react-icons/fa';
import React, { useState } from 'react';

import styles from './index.module.scss';

export default function TitleCard({ children, title, vertical }) {
  return (
    <div className={`${styles.card} ${vertical ? styles.vertical : ''}`}>
      <div className={styles.title}>{title}</div>
      <div className={styles.children}>{children}</div>
    </div>
  );
}
