import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from 'Assets/svg/right-arrow-g1.svg';
import { ReactComponent as RightArrow } from 'Assets/svg/right-arrow-g2.svg';
import Styles from './index.module.scss';

const range = (from, to, step = 1) => {
  let i = from;
  const rangeNum = [];

  while (i <= to) {
    rangeNum.push(i);
    i += step;
  }

  return rangeNum;
};

const Pagination = ({ onPageChange, pageLimit, totalRecords }) => {
  const [pageItems, setPageItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalRecords / pageLimit);

  const goToPage = page => {
    const currentPageN = Math.max(0, Math.min(page, totalPages));
    setCurrentPage(currentPageN);
    onPageChange({ currentPage: currentPageN });
  };

  const fetchPages = () => {
    // max number of page number's to display
    const limit = 4;
    // calculating the page number to start from
    const baseStart = currentPage / limit;
    const baseEnd = Math.floor(baseStart);
    let numRange;

    if (baseStart < 1) {
      // determine end number in page range to be displayed
      const endRange = totalPages < limit ? totalPages : limit;
      numRange = range(1, endRange);
    }

    if (baseStart > 1 && currentPage % 4 !== 0) {
      const startNum = baseEnd * limit + 1;
      const startEnd = baseEnd * limit + 4;

      if (startEnd <= totalPages) {
        numRange = range(startNum, startEnd);
      } else if (startEnd > totalPages) {
        const numDiff = startEnd - totalPages;
        numRange = range(startNum - numDiff, totalPages);
        // numRange = range(startNum, totalPages);
      }
    }

    if (currentPage % 4 === 0) {
      const startNum = (baseEnd - 1) * limit + 1;
      const startEnd = (baseEnd - 1) * limit + 4;
      numRange = range(startNum, startEnd);
    }

    return numRange;
  };

  useEffect(() => {
    goToPage(1);
  }, [totalRecords]);

  useEffect(() => {
    if (currentPage <= totalPages) {
      setPageItems(fetchPages());
      const currentPageN = Math.max(0, Math.min(currentPage, totalPages));
      onPageChange({ currentPage: currentPageN });
    }
  }, [currentPage]);

  if (totalPages === 1 || !totalRecords) return null;

  return (
    <div className={Styles.pagination__container}>
      <button
        className={Styles.pagination__button__previous}
        onClick={() => setCurrentPage(pageD => pageD - 1)}
        disabled={currentPage <= 1}
      >
        <LeftArrow />
      </button>
      {pageItems.map(item => (
        <button
          key={item}
          className={`${Styles.pagination__button__key} ${
            currentPage === item ? Styles.pagination__button__active : ''
          }`}
          onClick={() => setCurrentPage(item)}
        >
          {item}
        </button>
      ))}
      <button
        className={Styles.pagination__button__next}
        onClick={() => {
          if (currentPage <= totalPages) {
            setCurrentPage(pageD => pageD + 1);
          }
        }}
        disabled={currentPage === totalPages}
      >
        <RightArrow />
      </button>
    </div>
  );
};

export default Pagination;
