import {
  FETCH_PRODUCTS_ERROR,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS
} from 'store/actions/types/product';
import { SET_FORM, SET_INPUTS } from 'store/actions/types/input';

const INITIAL_STATE = {
  loading: false,
  error: null,
  products: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${SET_FORM}_PRODUCT`:
      return {
        ...state,
        [action.form]: action.value
      };
    case `${SET_INPUTS}_PRODUCT`:
      return {
        ...state,
        [action.form]: {
          ...state[action.form],
          [action.key]: action.value
        }
      };
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: action.loading,
        error: null
      };
    case FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.products,
        loading: false
      };

    default:
      return state;
  }
};
