import React from 'react';

import NewHeader from 'Components/NewHeader';
import styles from './index.module.scss';
import Footer from 'Components/NewFooter';

const Privacy = () => {
  return (
    <div className={styles.privacy}>
      <NewHeader background />
      <main className={styles.main}>
        <h1>Privacy Policy</h1>
        <p className={styles.main__subHeading}>
          Effective date: November, 2019
        </p>

        <section className={styles.policy}>
          <div>
            PropertyPurse ("us", "we", or "our") operates the
            https://proppurse.com website (the "Service"). <br />
            <br />
            This page informs you of our policies regarding the collection, use,
            and disclosure of personal data when you use our Service and the
            choices you have associated with that data. <br />
            <br />
            We use your data to provide and improve the Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this Privacy
            Policy, terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, accessible from https://proppurse.com
          </div>
          <h3>Information Collection And Use</h3>
          <div>
            We collect several different types of information for various purposes
            to provide and improve our Service to you.
          </div>
          <h3>Types of Data Collected</h3>
          <h4>Personal Data</h4>
          <div>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to:
          </div>
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Cookies and Usage Data</li>
          </ul>
          <h4>Usage Data</h4>
          <div>
            We may also collect information how the Service is accessed and used
            ("Usage Data"). This Usage Data may include information such as your
            computer's Internet Protocol address (e.g. IP address), browser type,
            browser version, the pages of our Service that you visit, the time and
            date of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </div>
          <h4>Tracking & Cookies Data</h4>
          <div>
            We use cookies and similar tracking technologies to track the activity
            on our Service and hold certain information.
            <br />
            <br />
            Cookies are files with small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Tracking technologies also used are
            beacons, tags, and scripts to collect and track information and to
            improve and analyze our Service.
            <br />
            <br />
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </div>
          <h4>Examples of Cookies we use:</h4>
          <ul>
            <li>
              Session Cookies. We use Session Cookies to operate our Service.
            </li>
            <li>
              Preference Cookies. We use Preference Cookies to remember your
              preferences and various settings.
            </li>
            <li>
              Security Cookies. We use Security Cookies for security purposes.
            </li>
          </ul>
          <h4>Use of Data</h4>
          <p>PropertyPurse uses the collected data for various purposes:</p>
          <ul>
            <li>To provide and maintain the Service</li>
            <li>To notify you about changes to our Service</li>
            <li>
              To allow you to participate in interactive features of our Service
              when you choose to do so
            </li>
            <li>To provide customer care and support</li>
            <li>
              To provide analysis or valuable information so that we can improve
              the Service
            </li>
            <li>To monitor the usage of the Service</li>
            <li>To detect, prevent and address technical issues</li>
          </ul>
          <h3>Transfer of data</h3>
          <div>
            Your information, including Personal Data, may be transferred to — and
            maintained on — computers located outside of your state, province,
            country or other governmental jurisdiction where the data protection
            laws may differ than those from your jurisdiction.
            <br />
            <br />
            If you are located outside Nigeria and choose to provide information
            to us, please note that we transfer the data, including Personal Data,
            to Nigeria and process it there.
            <br />
            <br />
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
            <br />
            <br />
            PropertyPurse will take all steps reasonably necessary to ensure that
            your data is treated securely and in accordance with this Privacy
            Policy and no transfer of your Personal Data will take place to an
            organization or a country unless there are adequate controls in place
            including the security of your data and other personal information.
          </div>
          <h3>Disclosure of data</h3>
          <h4>Legal Requirements</h4>
          <p>PropertyPurse uses the collected data for various purposes:</p>
          <ul>
            <li>To comply with a legal obligation</li>
            <li>To protect and defend the rights or property of PropertyPurse</li>
            <li>
              To prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              To protect the personal safety of users of the Service or the public
            </li>
            <li>To protect against legal liability</li>
          </ul>
          <h4>Security of data</h4>
          <div>
            The security of your data is important to us, but remember that no
            method of transmission over the Internet, or method of electronic
            storage is 100% secure. While we strive to use commercially acceptable
            means to protect your Personal Data, we cannot guarantee its absolute
            security.
          </div>
          <h4>Service Providers</h4>
          <div>
            We may employ third party companies and individuals to facilitate our
            Service ("Service Providers"), to provide the Service on our behalf,
            to perform Service-related services or to assist us in analyzing how
            our Service is used.
            <br />
            <br />
            These third parties have access to your Personal Data only to perform
            these tasks on our behalf and are obligated not to disclose or use it
            for any other purpose.
          </div>
          <h3>Links to other sites</h3>
          <div>
            Our Service may contain links to other sites that are not operated by
            us. If you click on a third party link, you will be directed to that
            third party's site. We strongly advise you to review the Privacy
            Policy of every site you visit.
            <br />
            <br />
            We have no control over and assume no responsibility for the content,
            privacy policies or practices of any third party sites or services.
          </div>
          <h3>Children’s Privacy</h3>
          <div>
            Our Service does not address anyone under the age of 18 ("Children").
            <br />
            <br />
            We do not knowingly collect personally identifiable information from
            anyone under the age of 18. If you are a parent or guardian and you
            are aware that your Children has provided us with Personal Data,
            please contact us. If we become aware that we have collected Personal
            Data from children without verification of parental consent, we take
            steps to remove that information from our servers.
          </div>
          <h3>Changes To This Privacy Policy</h3>
          <div>
            We may update our Privacy Policy from time to time. We will notify you
            of any changes by posting the new Privacy Policy on this page.
            <br />
            <br />
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the
            "effective date" at the top of this Privacy Policy.
            <br />
            <br />
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </div>
          <h3>General Data Protection Regulation (GDPR)</h3>
          <div>
            We are a Data Controller of your information.
            <br />
            <br />
            PropertyPurse legal basis for collecting and using the personal
            information described in this Privacy Policy depends on the Personal
            Information we collect and the specific context in which we collect
            the information:
          </div>
          <ul>
            <li>PropertyPurse needs to perform a contract with you</li>
            <li>You have given PropertyPurse permission to do so</li>
            <li>
              Processing your personal information is in PropertyPurse legitimate
              interests
            </li>
            <li>PropertyPurse needs to comply with the law</li>
          </ul>
          <div>
            PropertyPurse will retain your personal information only for as long
            as is necessary for the purposes set out in this Privacy Policy. We
            will retain and use your information to the extent necessary to comply
            with our legal obligations, resolve disputes, and enforce our
            policies. If you are a resident of the European Economic Area (EEA),
            you have certain data protection rights. If you wish to be informed
            what Personal Information we hold about you and if you want it to be
            removed from our systems, please contact us.
            <br />
            <br />
            In certain circumstances, you have the following data protection
            rights:
          </div>
          <ul>
            <li>
              The right to access, update or to delete the information we have on
              you.
            </li>
            <li>The right of rectification.</li>
            <li>The right to object.</li>
            <li>The right of restriction.</li>
            <li>The right to data portability</li>
            <li>The right to withdraw consent</li>
          </ul>
          <h3>Contact Us</h3>
          <div>
            If you have any questions about this Privacy Policy, please{' '}
            <a href="/contact">contact us</a>
          </div>
        </section>
      </main>
      {/* <section className={styles.hero}>
        <div className={styles.hero__container}>
          <div className={styles.text}>
            Privacy Policy
            <p> Effective date: November, 2019 </p>
          </div>
        </div>
      </section> */}
      <Footer />
    </div>
  );
};

export default Privacy;
