// import CustomAccordion from './CustomAccordion';
import Accordion from '../FAQ/accord';
import Footer from 'Components/NewFooter';
import MoveDown from 'Components/MoveDown';
import PromoCard from 'Components/promoCard';
import NewHeader from 'Components/NewHeader';
import React from 'react';
import building from 'Assets/svg/building.svg';
import paymentMethod from 'Assets/svg/payment-method.svg';
import people from 'Assets/svg/people.svg';
import planimg from 'Assets/images/planimg.png';
import styles from './index.module.scss';
// import { useHistory } from 'react-router-dom';
import whatwedo from 'Assets/images/whatwedo.png';
import PlanCard from 'Components/planCard';

const Homepage = () => {
  // const history = useHistory();
  // const handleClick = () => {
  //   history.push('/');
  // };

  return (
    <div className={styles.home}>
      <NewHeader />
      <section className={styles.hero}>
        <div className={styles.container}>
          <p>
            <span>Easy and</span> <br />
            <span>Affordable</span> <br />
            <span>Investments</span>
          </p>

          <div className={styles.message}>
            Our main focus here at property purse is on the youths who earn less
            than the average income and who wish to actively invest in real
            estate
          </div>
          <MoveDown />
        </div>
      </section>

      <section className={styles.about}>
        <div className={styles.top}>
          <div className={styles.left}>
            We also provide <br />
            <b>
              real estate advisory <br /> services and easy-to-understand
              payment plans
            </b>
          </div>
          <div className={styles.right}>
            We specialize in the provision of premium service through
            exceptional marketing to help facilitate real estate investments for
            all our clients.
            <span></span>
          </div>
        </div>
      </section>
      <section className={styles.more}>
        <div className={styles.summary}>
          <span className={styles.title}>What we do</span>
          <div className={styles.imgboxmobile}>
            <img src={whatwedo} alt="what we do" />
          </div>
          <div className={styles.summarybox}>
            <div className={styles.sect}>
              <div className={styles.img}>
                <img src={people} alt="icon" />
              </div>
              <div className={styles.cont}>
                Help the youth save on a daily or weekly basis
              </div>
            </div>
            <div className={styles.sect}>
              <div className={styles.img}>
                <img src={building} alt="icon" />
              </div>
              <div className={styles.cont}>
                Provide the knowledge of real estate investment
              </div>
            </div>
            <div className={styles.sect}>
              <div className={styles.img}>
                <img src={paymentMethod} alt="icon" />
              </div>
              <div className={styles.cont}>
                Make buying of land easy and convenient.
              </div>
            </div>

            <div className={styles.imgbox}>
              <img src={whatwedo} alt="what we do" />
              <div className={styles.box}>
                <p>Read More About Us</p>
                <button>Our Company</button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.boxmobile}>
          <p>Read More About Us</p>
          <button>Our Company</button>
        </div>
      </section>

      <section className={styles.unique}>
        <div className={styles.inner}>
          <h2>What Makes Us Unique ?</h2>
          <div className={styles.qualities}>
            <div className={styles.quality}>
              We render fast, timely <br /> and efficient services.
            </div>
            <div className={`${styles.quality} ${styles.green}`}>
              We market and sell <br /> value properties.
            </div>
            <div className={styles.quality}>
              We give customers value for their money and offer best bargains.
            </div>
            <div className={styles.quality}>
              We offer peace and comfort in transactions such that our customers
              don't have to worry of any unpleasant occurrence.
            </div>
            <div className={`${styles.quality} ${styles.green}`}>
              We are a socially responsible and responsive organization that
              seeks to give to society the part of what it has gotten from it.
            </div>
            <div className={styles.quality}>
              We encourage investment across individuals from various
              socio-economic backgrounds.
            </div>
          </div>
        </div>
      </section>
      <section className={styles.plans}>
        <div className={styles.left}>
          <div className={styles.content}>
            <h2>Our Payment Plans</h2>
          </div>
          <div className={styles.right} />
          <div className={styles.imgcontent}>
            <img src={planimg} alt="our plan" />
          </div>
          <div className={styles.plancontent}>
            <PlanCard
                top="DAILY PLAN"
                pricing="100 - 1000"
                lower="Minimum fee deducted"
                bodyText="On a daily basis depending on your income bracket for the property you wish to purchase or invest in."
                whereTo="/plans"
                actText="LEARN MORE"
              />
              <PlanCard
                topColor
                top="WEEKLY PLAN"
                pricing="1000 - 5000"
                lower="Minimum fee deducted"
                bodyText="On a weekly basis depending on your income bracket for the property you wish to purchase or invest in."
                whereTo="/plans"
                actText="LEARN MORE"
              />
              <PlanCard
                top="MONTHLY PLAN"
                bodyText="A pre-agreed sum of money will be deducted from your bank account on a monthly basis for the acquisition of land or property on the website"
                whereTo="/plans"
                actText="LEARN MORE"
              />
              <PlanCard
                topColor
                top="INSTALLMENT PLAN"
                bodyText="Payments can also be made randomly in installments."
                whereTo="/plans"
                actText="LEARN MORE"
              />
            {/* <div className={styles.singleplan}>
              <div className={styles.top}>DAILY PLAN</div>
              <div className={styles.body}>
                <h2>100 - 1000</h2>
                <div>Minimum fee deducted</div>
                <hr />
                <p>
                  On a daily basis depending on your income bracket for the
                  property you wish to purchase or invest in.
                </p>
              </div>
              <div className={styles.bottom}>LEARN MORE</div>
            </div>
            <div className={styles.singleplan}>
              <div className={styles.top}>WEEKLY PLAN</div>
              <div className={styles.body}>
                <h2>1000 - 5000</h2>
                <div>Minimum fee deducted</div>
                <hr />
                <p>
                  On a weekly basis depending on your income bracket for the
                  property you wish to purchase or invest in.
                </p>
              </div>
              <div className={styles.bottom}>LEARN MORE</div>
            </div>
            <div className={styles.singleplan}>
              <div className={styles.top}>MONTHLY PLAN</div>
              <div className={styles.body}>
                {/* <h2>100 - 1000</h2>
                <div>Minimum fee deducted</div> *
                <hr />
                <p>
                  A pre-agreed sum of money will be deducted from your bank
                  account on a monthly basis for the acquisition of land or
                  property on the website
                </p>
              </div>
              <div className={styles.bottom}>LEARN MORE</div>
            </div>
            <div className={styles.singleplan}>
              <div className={styles.top}>INSTALLMENT PLAN</div>
              <div className={styles.body}>
                {/* <h2>100 - 1000</h2>
                <div>Minimum fee deducted</div> *
                <hr />
                <p>Payments can also be made randomly in installments.</p>
              </div>
              <div className={styles.bottom}>LEARN MORE</div>
            </div> */}
          </div>
        </div>
      </section>
      <section className={styles.faqSection}>
        <h2 className={styles.heading}>
          Frequently <br /> Asked Questions
        </h2>
        <div className={styles.accord}>
          <Accordion title="Why should I use property purse?">
            We strive to satisfy our customers and bring them the very best and most affordable properties.
          </Accordion>
          <Accordion title="How does it work?">
            All you need to do is click the signup, fill the short form after which you select a product
            of your choosing and the payment plan which best suits your income pattern. Then you enter your
            debit card/ account details and make your first installment.
          </Accordion>
          <Accordion title="Are there any setup fees or any other charges?">
            There are no setup fees or other charges.
          </Accordion>
        </div>
        {/* <CustomAccordion /> */}
      </section>

      {/* <section className={styles.bottomsave}>
        <div className={styles.savefuture}>
          <h2>Ready to save</h2>
          <h2>for your future ?</h2>
          <button>Get Started</button>
        </div>
      </section> */}
      <PromoCard />

      <Footer />
    </div>
  );
};

export default Homepage;
