import React, { useState } from 'react';

import DropDown from 'Components/DropDown';
import Table from 'Components/Table';
import styles from './index.module.scss';

export default function DashboardHistory() {
  return (
    <div className={styles.history}>
      <h2>Activity History</h2>
      <DropDown blue options={[{ name: 'All' }, { name: 'Recent' }]} />

      <div className={styles.table}>
        <div className={styles.buttons}>
          <DropDown
            filter
            light
            options={[{ name: 'Filter' }, { name: 'Recent' }]}
          />
          <DropDown
            light
            options={[{ name: 'Sort By' }, { name: 'By Time' }]}
          />
        </div>

        <Table
          showFoot
          paginate={5}
          header={['Payment', 'Amount', 'Bank', 'Date']}
          data={[
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            }
          ]}
          conditions={{
            badge: 'successful'
          }}
          dataType={[
            { payment: 'text' },
            { amount: 'cash' },
            { bank: 'text' },
            { date: 'smallText' }
          ]}
        />
      </div>
    </div>
  );
}
