import React, { useState } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';

import { Link } from 'react-router-dom';
import includesAll from '../../utils/includesAll';
import { setNotify } from '../../store/actions/notify';
import siteLogo from 'Assets/logo/property-purse-logo-gold.png';
import spinner from '../../Assets/svg/spinner.svg';
import styles from './index.module.scss';
import { verifyOtp } from '../../store/actions/auth';

const EnterOtp = ({ history }) => {
  const [info, setInfo] = useState({});
  const sendingOtp = useMappedState(state => state.auth);

  const dispatch = useDispatch();

  const onChange = e => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!includesAll(Object.keys(info), ['otp'])) {
      return dispatch(
        setNotify({
          type: 'error',
          title: 'Enter your OTP code',
          body: 'Check your email for your OTP'
        })
      );
    }

    dispatch(
      verifyOtp(
        {
          ...info
        },
        history
      )
    );
  };

  return (
    <main className={styles.mainSection}>
      <section className={styles.leftSection}>
        <div className={styles.logoImg}>
          <Link to="/" className={styles.logoImg}>
            <img src={siteLogo} alt="logo" />
          </Link>
        </div>
        <div className={styles.headline}>
          You are welcome
          <br />
          <p>Enter the OTP code sent to your registered email</p>
        </div>
        <hr />
      </section>
      <section className={styles.rightSection}>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="otp">
              <input
                type="text"
                name="otp"
                id="otp"
                placeholder="Enter your OTP code"
                value={info.otp}
                onChange={onChange}
              />
            </label>
          </div>
          <button
            type="submit"
            className={styles.subscribeButton}
            disabled={sendingOtp.isLoading}
            value={spinner}
          >
            {sendingOtp.isLoading ? (
              <img src={spinner} alt="" />
            ) : (
              <span>Verify Otp</span>
            )}
          </button>
        </form>
      </section>
    </main>
  );
};

export default EnterOtp;
