import { Link } from 'react-router-dom';
import React from 'react';
import siteLogo from 'Assets/logo/property-purse-logo-gold.png';
import styles from './index.module.scss';

const ForgotPassword = () => {
  return (
    <main className={styles.mainSection}>
      <section className={styles.leftSection}>
        <Link to="/" className={styles.logoImg}>
          <img src={siteLogo} alt="logo" />
        </Link>
        <div className={styles.headline}>
          Seems like you Forgot your password
          <br />
          <p>Enter the mail associated with your account</p>
        </div>
        <hr />
      </section>
      <section className={styles.rightSection}>
        <form action="POST">
          <p className={styles.heading}>A code will be sent to your mail</p>
          {/* <p className={styles.tagline}>
            Fill the required fields below so we can get in touch with you
          </p> */}

          <div>
            <label htmlFor="email">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                required
                // value={fullName}
                // onChange={e => setFullName(e.target.value)}
              />
            </label>
          </div>

          <div className={styles.foot}>
            <span>
              Or you can just <Link to="/login"> Sign in</Link>
            </span>
          </div>
          <input
            type="submit"
            className={styles.subscribeButton}
            // disabled={loading}
            value="Send"
          />
        </form>
      </section>
    </main>
  );
};

export default ForgotPassword;
