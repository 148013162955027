import React from 'react';

import Footer from 'Components/NewFooter';
import NewHeader from 'Components/NewHeader';
import NewHero from 'Components/NewHero';
import PromoCard from 'Components/promoCard';
import PlanCard from 'Components/planCard';
import styles from './index.module.scss';

const Plans = () => {

  return (
    <div className={styles.plan}>
      <NewHeader />
      <main>
        <NewHero mainHeading="Available Plans"/>
        <section className={styles.planPage}>
          <div className={styles.cards}>
            <PlanCard
              top="DAILY PLAN"
              pricing="100 - 1000"
              lower="Minimum fee deducted"
              bodyText="On a daily basis depending on your income bracket for the property you wish to purchase or invest in."
              whereTo="/signup"
              actText="GET STARTED"
            />
            <PlanCard
              topColor
              top="WEEKLY PLAN"
              pricing="1000 - 5000"
              lower="Minimum fee deducted"
              bodyText="On a weekly basis depending on your income bracket for the property you wish to purchase or invest in."
              whereTo="/signup"
              actText="GET STARTED"
            />
            <PlanCard
              top="MONTHLY PLAN"
              bodyText="A pre-agreed sum of money will be deducted from your bank account on a monthly basis for the acquisition of land or property on the website"
              whereTo="/signup"
              actText="GET STARTED"
            />
            <PlanCard
              topColor
              top="INSTALLMENT PLAN"
              bodyText="Payments can also be made randomly in installments."
              whereTo="/signup"
              actText="GET STARTED"
            />
          </div>
          <PromoCard />
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Plans;
