import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom';

// import About from 'Pages/About';
import ComingSoon from 'Pages/ComingSoon';
import Contact from 'Pages/contact';
import DashBoard from 'Pages/Dashboard';
import DashboardHistory from 'Pages/Dashboard/History';
import DashboardPlans from 'Pages/Dashboard/Plans';
import DashboardRoute from 'Components/DashboardRoute';
import EnterOtp from 'Pages/EnterOTPcode';
import FAQ from 'Pages/FAQ';
import ForgotPassword from 'Pages/ForgotPassword';
// import Gallery from 'Pages/Gallery';
import Homepage from 'Pages/Homepage';
import Login from 'Pages/Login';
import MainDashboard from 'Pages/Dashboard/MainDashboard';
import NewAbout from 'Pages/NewAbout';
import NewGallery from 'Pages/NewGallery';
import NotFound from 'Pages/notFound';
import Notify from 'Components/Notify';
import OnboardDuration from 'Pages/onboard/duration';
import OnboardGallery from 'Pages/onboard/gallery';
import OnboardPayment from 'Pages/onboard/pay';
import OnboardPlan from 'Pages/onboard/plan';
import OurCompany from 'Pages/NewAbout/OurCompany';
import Plans from 'Pages/plans';
import Privacy from 'Pages/privacy';
import ProtectedRoute from 'Components/ProtectedRoute';
import React from 'react';
import ScrollToTop from 'Components/ScrollToTop';
import Signup from 'Pages/Signup';
import TermsAndConditions from 'Pages/TermsAndConditions';
import AccountDashboard from 'Pages/Dashboard/Account';

function App() {
  return (
    <Router>
      <Notify />
      <Switch>
        <Route path="/coming-soon">
          <ComingSoon />
        </Route>

        <Route path="/" exact>
          <Homepage />
        </Route>
        <Route path="/faq" exact>
          <FAQ />
        </Route>
        <Route path="/about" exact>
          <NewAbout />
        </Route>
        <Route path="/about/our-company">
          <OurCompany />
        </Route>
        <Route path="/gallery">
          <NewGallery />
        </Route>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route path="/signup" component={Signup} />
        <Route path="/onboarding" component={EnterOtp} />
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/terms-and-conditions">
          <TermsAndConditions />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/plans">
          <Plans />
        </Route>
        <ProtectedRoute path="/onboard/gallery" component={OnboardGallery} />

        <Route exact path="/onboard">
          <Redirect to="/onboard/gallery" />
        </Route>
        <ProtectedRoute path="/onboard/duration" component={OnboardDuration} />
        <ProtectedRoute path="/onboard/plan" component={OnboardPlan} />
        <ProtectedRoute path="/onboard/payment" component={OnboardPayment} />

        <DashboardRoute exact path="/dashboard" component={MainDashboard} />
        <DashboardRoute
          path="/dashboard/account"
          component={AccountDashboard}
        />
        <DashboardRoute path="/dashboard/plans" component={DashboardPlans} />
        <DashboardRoute
          path="/dashboard/history"
          component={DashboardHistory}
        />

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
      <ScrollToTop />
    </Router>
  );
}

export default App;
