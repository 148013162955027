import {
  AUTH_USER_ERROR,
  AUTH_USER_REQUEST,
  AUTH_USER_SUCCESS,
  LOGOUT_USER
} from './types/users';

import Axios from 'axios';
import { getCurrentPlan } from './plan';
import { setNotify } from './notify';

// const token = `0911f88dd190789f16ee1ec43d0340de360f4895ed2d4204091bb63bf3c1bd95da7161d4b689`;

const authUserRequest = state => ({
  type: AUTH_USER_REQUEST,
  state
});
const authUserError = error => ({
  type: AUTH_USER_ERROR,
  error
});

export const authUserSuccess = user => ({
  type: AUTH_USER_SUCCESS,
  user
});

const logoutUserSuccess = () => ({
  type: LOGOUT_USER
});

export const signupUser = (details, history) => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.post('/auth/signup', {
    ...details
  })
    .then(async ({ data }) => {
      Axios.defaults.headers.common['x-access-token'] = data.user.token;
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('token', data.user.token);
      dispatch(authUserSuccess({ ...data.user }));
      dispatch(
        setNotify({
          title: 'Signed up Sucessfully',
          body: data.message,
          type: 'success'
        })
      );
      return history && history.push('/onboarding');
    })
    .catch(error => {
      dispatch(authUserRequest(false));
      if (error.response) {
        dispatch(authUserError(error.response.data));
        if (error.response.data.errors) {
          return dispatch(
            setNotify({
              title: error.response.data.errors.param,
              body: error.response.data.errors.msg,
              type: 'error'
            })
          );
        } else {
          return dispatch(
            setNotify({
              title: 'Error',
              body: error.response.data.message,
              type: 'error'
            })
          );
        }
      }
    });
};

export const verifyOtp = (details, history, action, norefresh) => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.post('/auth/otp/verify', {
    ...details
  })
    .then(async ({ data }) => {
      dispatch(authUserRequest(false));
      dispatch(
        setNotify({
          title: 'Success',
          body: data.message,
          type: 'success'
        })
      );
      !norefresh && dispatch(refreshUser());
      action && action();
      return history && history.push('/onboard');
    })
    .catch(error => {
      dispatch(authUserRequest(false));
      if (error.response) {
        dispatch(authUserError(error.response.data));
        if (error.response.data.errors) {
          return dispatch(
            setNotify({
              title: error.response.data.errors.param,
              body: error.response.data.errors.msg,
              type: 'error'
            })
          );
        } else {
          return dispatch(
            setNotify({
              title: 'Error',
              body: error.response.data.message,
              type: 'error'
            })
          );
        }
      }
    });
};

export const loginUser = (details, history) => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.post('/auth/login', {
    ...details
  })
    .then(async ({ data }) => {
      Axios.defaults.headers.common['x-access-token'] = data.user.token;
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...data.user,
          token: data.user.token
        })
      );
      localStorage.setItem('token', data.user.token);
      await dispatch(refreshUser());
      dispatch(getCurrentPlan());
      dispatch(
        setNotify({
          title: 'Success',
          body: 'Logged in successfully',
          type: 'success'
        })
      );
      return history && history.push('/dashboard');
    })
    .catch(error => {
      dispatch(authUserRequest(false));
      if (error.response) {
        dispatch(authUserError(error.response.data));
        return dispatch(
          setNotify({
            title: 'Error',
            body: error.response.data.message,
            type: 'error'
          })
        );
      }
    });
};

export const reSendOtp = () => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.get('/otp/resend')
    .then(async ({ data }) => {
      dispatch(authUserRequest(false));
      dispatch(
        setNotify({
          title: 'Success',
          body: data.message,
          type: 'success'
        })
      );
    })
    .catch(error => {
      dispatch(authUserRequest(false));
      if (error.response) {
        dispatch(authUserError(error.response.data));
        return dispatch(
          setNotify({
            title: 'Error',
            body: error.response.data.message,
            type: 'error'
          })
        );
      }
    });
};

export const forgotPassword = (details, action) => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.post('/resetPassword', {
    ...details
  })
    .then(async ({ data }) => {
      dispatch(authUserRequest(false));
      Axios.defaults.headers.common['x-access-token'] = data.token;
      action && action();
      dispatch(
        setNotify({
          title: 'Success',
          body: data.message,
          type: 'success'
        })
      );
    })
    .catch(error => {
      dispatch(authUserRequest(false));
      if (error.response) {
        dispatch(authUserError(error.response.data));
        return dispatch(
          setNotify({
            title: 'Error',
            body: error.response.data.message,
            type: 'error'
          })
        );
      }
    });
};

export const logoutUser = history => async dispatch => {
  try {
    localStorage.clear();

    dispatch(logoutUserSuccess());
  } catch (error) {}
};

export const refreshUser = () => dispatch => {
  dispatch(authUserRequest(true));
  return Axios.get('/user')
    .then(async ({ data }) => {
      localStorage.setItem('user', JSON.stringify(data.user));
      dispatch(authUserSuccess({ ...data.user }));
    })
    .catch(error => {
      if (error.message && error.message === 'Network Error') {
        dispatch(
          setNotify({
            type: 'error',
            title: error.message,
            body: 'Please connect to the Internet'
          })
        );
      }
      dispatch(authUserError(error));
    });
};
