import React, { useState } from 'react';
import NewHeader from 'Components/NewHeader';
import NewHero from 'Components/NewHero';
import GetStartedSection from 'Components/GetStartedSection';
import Footer from 'Components/NewFooter';
import Pagination from 'Components/Pagination';

import Styles from './index.module.scss';
import GalleryCard from 'Components/GalleryCard';
import DropDown from 'Components/DropDown';
import InputRange from 'react-input-range';
import { ReactComponent as DownArrow } from 'Assets/svg/down-chevron-green.svg';
import GalleryModal from 'Components/GalleryModal';

const NewGallery = () => {
  const [currentData, setCurrentData] = useState([]);
  const [priceRange, setPriceRange] = useState({ min: 500000, max: 5000000 });
  const [propertyName, setPropertyName] = useState('');
  const [galleryModalOpen, setGalleryModalOpen] = useState(false);
  const pageLimit = 9;
  const collection = [...Array(67).keys()].map(i => ({
    id: i + 1,
    name: `Lorem Ipsum olor ${i + 1}`
  }));

  const onPageChange = data => {
    const { currentPage } = data;
    const offset = (currentPage - 1) * pageLimit;

    const list = collection.slice(offset, offset + pageLimit);
    setCurrentData(list);
  };

  return (
    <div>
      <NewHeader />
      <NewHero mainHeading="Gallery" />
      <main className={Styles.main__body}>
        <div className={Styles.blueOffsetBox} />
        <div className={Styles.greenOffsetBox} />
        <section className={Styles.searchBar}>
          <div className={Styles.container}>
            <div className={Styles.inner__container}>
              <div className={Styles.location__select}>
                <p>Select a location</p>
                <DownArrow height="100%" width="100%" />
              </div>
              <div className={Styles.propertyName__input}>
                <label htmlFor="propertyName">
                  <input
                    type="text"
                    id="propertyName"
                    name="propertyName"
                    placeholder="Enter property name"
                    value={propertyName}
                    onChange={e => setPropertyName(e.target.value)}
                  />
                </label>
              </div>
              <button>Search</button>
            </div>
          </div>
        </section>

        <section className={Styles.filter__controls__section}>
          <div className={Styles.filters}>
            <div className={Styles.filters__product}>
              <span>Filter by: </span>
              <DropDown
                title="by Land"
                options={[
                  { name: 'by Land', action: () => alert('by land') },
                  { name: 'by Building', action: () => alert('by building') },
                  { name: 'by Building', action: () => alert('by building') }
                ]}
              />
            </div>
            <div className={Styles.filters__price}>
              <span>Sort by price: </span>
              <DropDown
                title=""
                options={[
                  { name: 'Low to high', action: () => alert('Low to high') },
                  { name: 'High to low', action: () => alert('High to low') }
                ]}
              />
            </div>
            <div className={Styles.pricerange}>
              <span className={Styles.extra}>Price Range</span>
              <div className={Styles.range}>
                <InputRange
                  maxValue={10000000}
                  minValue={500000}
                  formatLabel={value => `${value.toLocaleString()}`}
                  step={2000000}
                  value={priceRange}
                  onChange={value => setPriceRange(value)}
                  onChangeComplete={value => console.log(value)}
                />
              </div>
            </div>
          </div>
        </section>

        <section className={Styles.products__section}>
          {currentData.map(item => (
            <GalleryCard
              item={item}
              key={item.id}
              action={() => setGalleryModalOpen(true)}
            />
          ))}
        </section>

        <section className={Styles.pagination__section}>
          <div>
            <Pagination
              onPageChange={onPageChange}
              pageLimit={9}
              totalRecords={collection.length}
            />
          </div>
        </section>
        <GetStartedSection />
        <GalleryModal
          open={galleryModalOpen}
          action={() => setGalleryModalOpen(false)}
        />
      </main>
      <Footer />
    </div>
  );
};

export default NewGallery;
