import { Link } from 'react-router-dom';
import React from 'react';
import Styles from './index.module.scss';
import facebookIcon from 'Assets/svg/facebook-icon.svg';
import instagramIcon from 'Assets/svg/instagram-icon.svg';
import linkedInIcon from 'Assets/svg/linkedin-icon.svg';
import siteLogo from 'Assets/logo/property-purse-logo-black.svg';
import twitterIcon from 'Assets/svg/twitter-icon.svg';

const Footer = () => {
  return (
    <footer className={Styles.footer}>
      <div className={Styles.footer__container}>
        <div className={Styles.footer__logo__container}>
          <div className={Styles.footer__logo}>
            <Link to="/">
              <img src={siteLogo} alt="logo" />
            </Link>
          </div>
          {/* <div className={Styles.footer__logo}>
            <img src={siteLogo} alt="logo" />
          </div> */}
        </div>

        <ul className={Styles.footer__nav}>
          <li>
            <Link to="/about">About us</Link>
          </li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/faq">Faq</Link>
          </li>
        </ul>

        <div className={Styles.footer__address}>
          <div className={Styles.footer__address__office}>
            <h4>office address</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing</p>
          </div>

          <div className={Styles.footer__address__tel}>
            <h4>Tel</h4>
            <p>192072510183</p>
          </div>

          <div className={Styles.footer__address__email}>
            <h4>Email</h4>
            <p>random@gmail.com</p>
          </div>
        </div>

        <div className={Styles.footer__social}>
          <h4>follow us on social media</h4>

          <div className={Styles.footer__social__icons}>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <img src={twitterIcon} alt="twitter icon" />
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <img src={instagramIcon} alt="instagram icon" />
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <img src={facebookIcon} alt="facebook" />
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <img src={linkedInIcon} alt="linkedin icon" />
            </a>
          </div>
        </div>

        <div className={Styles.footer__bottomSection}>
          <div className={Styles.footer__bottomSection__links}>
            <Link to="/privacy">Privacy</Link>
            <span />
            <Link to="/terms-and-conditions">Terms and conditions</Link>
          </div>
          <p>Copyright &copy; PropPurse {new Date().getFullYear()}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
