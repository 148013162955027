import React, { useState } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';

import { Link } from 'react-router-dom';
import includesAll from '../../utils/includesAll';
import { setNotify } from '../../store/actions/notify';
import { loginUser } from '../../store/actions/auth';
import spinner from '../../Assets/svg/spinner.svg';
import siteLogo from 'Assets/logo/property-purse-logo-gold.png';
import styles from './index.module.scss';

const Login = ({ history }) => {
  const [details, setDetails] = useState({});
  const user = useMappedState(state => state.auth)

  const dispatch = useDispatch();

  const handleInputChange = e => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (
      !includesAll(Object.keys(details), [
        'email',
        'password'
      ])
    ) {
      return dispatch(
        setNotify({
          type: 'error',
          title: 'All fields are required',
          body: 'Enter all the fields correctly'
        })
      );
    }

    dispatch(
      loginUser(
        {
          ...details,
          email: details.email.toLowerCase()
        },
        history
      )
    );
  };


  return (
    <main className={styles.mainSection}>
      <section className={styles.leftSection}>
        <Link to="/" className={styles.logoImg}>
          <img src={siteLogo} alt="logo" />
        </Link>
        <div className={styles.headline}>
          Welcome Back
          <br />
          <p>
            Enter your details to continue using PropertyPurse for your
            mortgages and properties
          </p>
        </div>
        <hr />
      </section>
      <section className={styles.rightSection}>
        <form onSubmit={handleSubmit}>
          {/* <p className={styles.heading}>
            For now let us know how we can help you
          </p>
          <p className={styles.tagline}>
            Fill the required fields below so we can get in touch with you
          </p> */}

          <div>
            <label htmlFor="email">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                required
                value={details.email}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div>
            <label htmlFor="password">
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                required
                value={details.password}
                onChange={handleInputChange}
              />
            </label>
          </div>

          <div className={styles.foot}>
            <span>
              Don't have an account ? <Link to="/signup"> Sign Up</Link>
            </span>
            <Link to="/forgot-password">Forgot Password</Link>
          </div>
          <button type="submit" disabled={user.loading}>
            {user.loading ? <img src={spinner} alt="" /> : <span>Login</span>}
          </button>
        </form>
      </section>
    </main>
  );
};

export default Login;
