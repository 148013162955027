import auth from './auth';
import { combineReducers } from 'redux';
import notify from './notify';
import paymentPlan from './paymentPlan';
import plan from './plan';
import product from './product';

export default combineReducers({
  notify,
  auth,
  product,
  plan,
  paymentPlan
});
