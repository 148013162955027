import React, { useState } from 'react';
import Table from 'Components/Table';
import DropDown from 'Components/DropDown';
import {
  FaChevronLeft,
  FaChevronRight,
  FaTrash,
  FaTrashAlt
} from 'react-icons/fa';
import styles from './index.module.scss';
import ButtonCard from 'Components/ButtonCard';
import DisplayCard from 'Components/DisplayCard';
import money from 'Assets/svg/save-money.svg';
import target from 'Assets/svg/target.svg';
import court from 'Assets/svg/court.svg';
import editIcon from 'Assets/svg/edit.svg';
import uploadIcon from 'Assets/svg/upload.svg';
import TitleCard from 'Components/TitleCard';

import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer
} from 'recharts';

let titleCardScroll;
const data = [
  {
    name: 'Jan',
    amt: 240000
  },
  {
    name: 'Feb',
    amt: 22100
  },
  {
    name: 'Mar',
    amt: 229000
  },
  {
    name: 'Apr',
    amt: 20000
  },
  {
    name: 'Jun',
    amt: 480000
  },
  {
    name: 'Jul',
    amt: 38000
  },
  {
    name: 'Aug',
    amt: 210000
  }
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className={styles.customToolTip}>
        <p className={styles.label}>Amount Contributed</p>
        <p className={styles.amount}>
          {`${payload[0].value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </p>
      </div>
    );
  }

  return null;
};
export default function AccountDashboard() {
  const [titleCardScrollIndicate, settitleCardScrollIndicate] = useState(0);
  return (
    <div className={styles.accounts}>
      <h2>Account Overview</h2>
      <div className={styles.cards}>
        <DisplayCard
          title="Amount Contributed so far"
          amount="500,000"
          icon={money}
        />
        <DisplayCard
          title="Target Amount"
          amount="500,000"
          icon={target}
          blue
        />
      </div>

      <div className={styles.graph}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            // margin={{ top: 50, right: 30, left: 30, bottom: 50 }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" tickMargin={15} tickLine={false} />
            <YAxis
              domain={[10000, 500000]}
              // domain={['dataMin', 'dataMax']}
              tickLine={false}
              tickMargin={5}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="amt"
              stroke="#3352CB"
              strokeWidth={4}
              dot={{ stroke: '#3EB337', strokeWidth: 1, width: 27 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.cardBtns}>
        <ButtonCard
          title="Modify Plan"
          desc="Make Changes to your plan"
          icon={editIcon}
        />
        <ButtonCard
          title="Top Up"
          desc="Add more money to your contribution"
          icon={uploadIcon}
          blue
          right
        />
      </div>

      <div className={styles.titleCards}>
        <TitleCard title="Banks" vertical>
          <div className={styles.lists}>
            <div
              className={styles.content}
              ref={item => {
                titleCardScroll = item;
              }}
            >
              <div className={styles.listPage}>
                <div className={styles.list}>
                  <div className={styles.icon}>
                    <img src={court} alt="bank" />
                  </div>
                  <div className={styles.body}>
                    <div className={styles.top}>Zenith Bank</div>
                    <div className={styles.desc}>
                      Account Number: 0972541416
                    </div>
                  </div>
                </div>
                <div className={styles.list}>
                  <div className={styles.icon}>
                    <img src={court} alt="bank" />
                  </div>
                  <div className={styles.body}>
                    <div className={styles.top}>Access Bank</div>
                    <div className={styles.desc}>
                      Account Number: 0972541416
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.listPage}>
                <div className={styles.list}>
                  <div className={styles.icon}>
                    <img src={court} alt="bank" />
                  </div>
                  <div className={styles.body}>
                    <div className={styles.top}>Zenith Bank</div>
                    <div className={styles.desc}>
                      Account Number: 0972541416
                    </div>
                  </div>
                </div>
                <div className={styles.list}>
                  <div className={styles.icon}>
                    <img src={court} alt="bank" />
                  </div>
                  <div className={styles.body}>
                    <div className={styles.top}>Access Bank</div>
                    <div className={styles.desc}>
                      Account Number: 0972541416
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.listPage}>
                <div className={styles.list}>
                  <div className={styles.icon}>
                    <img src={court} alt="bank" />
                  </div>
                  <div className={styles.body}>
                    <div className={styles.top}>Zenith Bank</div>
                    <div className={styles.desc}>
                      Account Number: 0972541416
                    </div>
                  </div>
                </div>
                <div className={styles.list}>
                  <div className={styles.icon}>
                    <img src={court} alt="bank" />
                  </div>
                  <div className={styles.body}>
                    <div className={styles.top}>Access Bank</div>
                    <div className={styles.desc}>
                      Account Number: 0972541416
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.paginates}>
              <div
                className={`${styles.dot} ${
                  titleCardScrollIndicate === 450 * 0 ? styles.active : ''
                }`}
                onClick={() => {
                  settitleCardScrollIndicate(450 * 0);
                  titleCardScroll &&
                    titleCardScroll.scrollTo({
                      behavior: 'smooth',
                      left: 450 * 0,
                      top: 0
                    });
                }}
              />
              <div
                className={`${styles.dot} ${
                  titleCardScrollIndicate === 450 * 1 ? styles.active : ''
                }`}
                onClick={() => {
                  settitleCardScrollIndicate(450 * 1);
                  titleCardScroll &&
                    titleCardScroll.scrollTo({
                      behavior: 'smooth',
                      left: 450 * 1,
                      top: 0
                    });
                }}
              />
              <div
                className={`${styles.dot} ${
                  titleCardScrollIndicate === 450 * 2 ? styles.active : ''
                }`}
                onClick={() => {
                  settitleCardScrollIndicate(450 * 2);
                  titleCardScroll &&
                    titleCardScroll.scrollTo({
                      behavior: 'smooth',
                      left: 450 * 2,
                      top: 0
                    });
                }}
              />
            </div>
          </div>

          <div className={styles.titleCardbtns}>
            <button>Add Bank</button>
            <button className={styles.trash}>
              <FaTrash />
            </button>
          </div>
        </TitleCard>
        <TitleCard title="Cards" vertical>
          <h2>No Card Added</h2>
          <button>Add Card</button>
        </TitleCard>
      </div>
      <div className={styles.table}>
        <div className={styles.top}>
          <div>Account Activity</div>
          <div className={styles.buttons}>
            <DropDown
              filter
              light
              options={[{ name: 'Filter' }, { name: 'Recent' }]}
            />
            <DropDown
              light
              options={[{ name: 'Sort By' }, { name: 'By Time' }]}
            />
          </div>
        </div>

        <Table
          showFoot
          paginate={5}
          header={['Payment', 'Amount', 'Bank', 'Date']}
          data={[
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            },
            {
              payment: 'Lorem ipsum',
              bank: 'Lorem ipsum',
              amount: 5000,
              date: new Date().toDateString()
            }
          ]}
          conditions={{
            badge: 'successful'
          }}
          dataType={[
            { payment: 'text' },
            { amount: 'cash' },
            { bank: 'text' },
            { date: 'smallText' }
          ]}
        />
      </div>
    </div>
  );
}
