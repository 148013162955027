import React, { useState } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';

import { Link } from 'react-router-dom';
import includesAll from '../../utils/includesAll';
import { setNotify } from '../../store/actions/notify';
import { signupUser } from '../../store/actions/auth';
import siteLogo from 'Assets/logo/property-purse-logo-gold.png';
import spinner from '../../Assets/svg/spinner.svg';
import styles from './index.module.scss';

const Signup = ({ history }) => {
  const [details, setDetails] = useState({});
  const user = useMappedState(state => state.auth);

  const dispatch = useDispatch();

  const handleInputChange = e => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (
      !includesAll(Object.keys(details), [
        'firstName',
        'lastName',
        'email',
        'password'
      ])
    ) {
      return dispatch(
        setNotify({
          type: 'error',
          title: 'All fields are required',
          body: 'Insert all fields correctly'
        })
      );
    }

    if (!/^[a-zA-Z- ]+$/.test(details.firstname)) {
      return dispatch(
        setNotify({
          type: 'error',
          title: 'First name error',
          body: 'First name must not contain a number'
        })
      );
    }

    if (!/^[a-zA-Z- ]+$/.test(details.lastname)) {
      return dispatch(
        setNotify({
          type: 'error',
          title: 'Last name error',
          body: 'Last name must not contain a number'
        })
      );
    }

    if (details.password !== details.password2) {
      return dispatch(
        setNotify({
          type: 'error',
          title: 'password',
          body: 'Your password does not match'
        })
      );
    }

    dispatch(
      signupUser(
        {
          ...details,
          email: details.email.toLowerCase()
        },
        history
      )
    );
  };

  return (
    <main className={styles.mainSection}>
      <section className={styles.leftSection}>
        <Link to="/" className={styles.logoImg}>
          <img src={siteLogo} alt="logo" />
        </Link>
        <div className={styles.headline}>
          Create an account
          <br />
          <p>
            Enter your details to create an account on PropertyPurse for your
            mortgages and properties
          </p>
        </div>
        <hr />
      </section>
      <section className={styles.rightSection}>
        <form onSubmit={handleSubmit}>
          {/* <p className={styles.heading}>
            For now let us know how we can help you
          </p>
          <p className={styles.tagline}>
            Fill the required fields below so we can get in touch with you
          </p> */}

          <div>
            <label htmlFor="firstname">
              <input
                type="firstname"
                name="firstName"
                id="firstname"
                placeholder="First Name"
                required
                value={details.firstName}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div>
            <label htmlFor="lastname">
              <input
                type="lastname"
                name="lastName"
                id="lastname"
                placeholder="Last Name"
                required
                value={details.lastName}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div>
            <label htmlFor="email">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                required
                value={details.email}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div>
            <label htmlFor="password">
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                required
                value={details.password}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div>
            <label htmlFor="password2">
              <input
                type="password"
                name="password2"
                id="password2"
                placeholder="Confirm Password"
                required
                value={details.confirmPassword}
                onChange={handleInputChange}
              />
            </label>
          </div>

          <div className={styles.foot}>
            <span>
              Have an account? <Link to="/login"> Login</Link>
            </span>
          </div>
          <button type="submit" disabled={user.loading}>
            {user.loading ? <img src={spinner} alt="" /> : <span>Sign Up</span>}
          </button>
        </form>
      </section>
    </main>
  );
};

export default Signup;
